import React from 'react';
import { Text } from 'react-konva';

const Tooth = ({ item, xPos, yPos, readOnly }) => {
  const refactorY = () => {
    let pos = 0;
    if (item.type === 1) {
      pos = item.hover ? item.y + 142 : item.y + 125;
    } else {
      pos = item.hover ? item.y - 65 : item.y - 55;
    }
    return pos;
  };

  return readOnly ? (
    <Text
      text={item.name}
      fontSize={item.hover ? 20 : 15}
      fontSize={15}
      x={xPos ? xPos : item.x + 12}
      y={yPos ? yPos : refactorY()}
    />
  ) : (
    <Text
      text={item.name}
      fontSize={item.hover ? 20 : 15}
      fontSize={15}
      x={item.x + 12}
      y={refactorY()}
    />
  );
};

export default Tooth;
