import { types } from '../../../actions';
import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import { apiCall } from '../../../../config/axiosApiCallWrapper';
import Ganancias from './Ganancias/saga';
import { setSelectedTypeSuccess, setSelectedType } from './actions';

function* getSumComprobSunat({ payload }) {
  const { dia } = payload;
  yield put(setSelectedType(dia));
}

function* selectedtypeComprob({ payload }) {
  const { dia } = payload;
  const type = yield select(
    (state) => state.admin.balanceSunat.diario.selectedTypeComprob
  );

  const idSucursal = yield select((state) => state.Auth.data.sucursal_id);
  const { selectedTabCategory } = yield select(
    (state) => state.admin.balanceSunat.diario
  );
  let route = '';
  switch (selectedTabCategory) {
    case 'ingresosClinica':
      route = 'admin/balanceSunat/getBalanceSunatCaja';
      break;
    case 'egresosClinica':
      route = 'admin/balanceSunat/getEgresosBalance';
      break;
    case 'ingresosLgst':
      route = 'admin/balanceSunat/getCajaTotalLgstAdvance';
      break;
    case 'egresosLgst':
      route = 'admin/balanceSunat/getEgresoTotalLgstAdvance';
      break;
    default:
      route = '';
      break;
  }
  try {
    const response = yield call(apiCall, 'POST', route, {
      type,
      idSucursal,
      dia,
    });
    if (response.success) {
      yield put(setSelectedTypeSuccess(dia, response, type));
    }
  } catch (error) {
    yield console.log(error);
  }
}

export function* watchGetSumComprobSunat() {
  yield takeLatest(
    types.admin.balanceSunat.diario.selectedTabCategory,
    getSumComprobSunat
  );
}

export function* watchSelectedtypeComprob() {
  yield takeLatest(
    types.admin.balanceSunat.diario.selectedtypeComprob,
    selectedtypeComprob
  );
}

export default function* rootSaga() {
  yield all([
    Ganancias(),
    fork(watchGetSumComprobSunat),
    fork(watchSelectedtypeComprob),
  ]);
}
