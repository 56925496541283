import { types } from '../../../actions';

const INIT_STATE = {
  selectedComprobSunat: [],
  comprobantes: { data: [] },
  loadingComprobantes: false,
  modalEditar: false,
  loadingBuscarDoc: false,
  selectedPacienteComprob: null,
  loadingSaveBoletaFactura: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.admin.balanceSunat.sunat.getSearchBoletaFactura: {
      return { ...state, loadingComprobantes: true };
    }
    case types.admin.balanceSunat.sunat.getSearchBoletaFacturaSuccess: {
      const { data } = action.payload;
      const comprobante = data.comprobantes;
      return {
        ...state,
        loadingComprobantes: false,
        comprobantes: comprobante,
      };
    }
    case types.admin.balanceSunat.sunat.openModalEditar: {
      const { item } = action.payload;
      return {
        ...state,
        modalEditar: true,
        selectedPacienteComprob: item,
      };
    }
    case types.admin.balanceSunat.sunat.closeModalEditar: {
      return {
        ...state,
        modalEditar: false,
      };
    }
    case types.admin.balanceSunat.sunat.buscarDocumento: {
      return {
        ...state,
        loadingBuscarDoc: true,
      };
    }
    case types.admin.balanceSunat.sunat.buscarDocumentoSuccess: {
      return {
        ...state,
        loadingBuscarDoc: false,
      };
    }
    case types.admin.balanceSunat.sunat.editSelectedPacienteComprob: {
      const { value, item } = action.payload;
      const comprobantes = {
        ...state.selectedPacienteComprob,
        detalles: state.selectedPacienteComprob.detalles.map((data) =>
          data.id === item.id ? { ...data, descripcion: value } : data
        ),
      };

      return {
        ...state,
        selectedPacienteComprob: comprobantes,
      };
    }
    case types.admin.balanceSunat.sunat.saveBoletaFactura: {
      return {
        ...state,
        loadingSaveBoletaFactura: true,
      };
    }
    case types.admin.balanceSunat.sunat.saveBoletaFacturaSuccess: {
      return {
        ...state,
        modalEditar: false,
        loadingSaveBoletaFactura: false,
      };
    }
    default:
      return { ...state };
  }
};
