import { types } from '../actions';

const initialState = {
  notification: [],
  id_paciente: { id_paciente: '' },
  nombre_paciente: '',
  pacienteData: {},
  id_cita: { id_cita: '' },
  estadoCaja: false,
  id_caja: '',
  loading: false,
  textLoading: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DATA_PACIENTE':
      return {
        ...state,
        pacienteData: action.payload,
      };
    case 'SET_NOMBRE_PACIENTE':
      return {
        ...state,
        nombre_paciente: action.payload,
      };
    case 'SET_PACIENTE':
      return {
        ...state,
        id_paciente: action.payload,
      };
    case 'SET_IDCITA':
      return {
        ...state,
        id_cita: action.payload,
      };
    case 'OPEN_CAJA':
      return {
        ...state,
        estadoCaja: true,
      };
    case 'CLOSE_CAJA':
      return {
        ...state,
        estadoCaja: false,
      };
    case 'SET_IDCAJA':
      return {
        ...state,
        id_caja: action.payload,
      };
    case 'LOADING':
      return {
        ...state,
        loading: true,
        textLoading: action.payload,
      };
    case 'LOADING_DISABLE':
      return {
        ...state,
        loading: false,
        textLoading: '',
      };
    case types.systemConf.GET_STATE_CONFIG:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
