import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  setAllPatientes,
  setPremiosPaciente,
  closeAllSaveRetiro,
  setAllPatientsRetiro,
  closeSaveRetiro,
} from './Actions';
import { types } from '../../actions';
import { apiCall } from '../../../config/axiosApiCallWrapper';

function* getPacientes({ payload }) {
  const { search } = payload;
  try {
    const response = yield call(
      apiCall,
      'POST',
      'app/fairdent/getDataPremios',
      {
        search,
      }
    );
    yield put(setAllPatientes(response.premios));
  } catch (err) {
    console.log(err);
  }
}

function* getPremiosPaciente({ payload }) {
  const pacienteUser = payload.pacienteuser_id;
  try {
    const response = yield call(apiCall, 'POST', 'app/fairdent/getDataPremio', {
      pacienteUser,
    });
    yield put(setPremiosPaciente(response.premio));
  } catch (err) {
    console.log(err);
  }
}

function* saveRecojoPremio({ payload }) {
  const dataForm = payload;
  console.log(payload);
  const formData = new FormData();
  formData.append('idJugada', dataForm.idJugada);
  formData.append('idUser', dataForm.idUser);
  formData.append('fecha', dataForm.fecha);
  formData.append('descripcion', dataForm.descripcion);
  formData.append('evidencia', dataForm.evidencia);
  const response = yield call(
    apiCall,
    'POST',
    'app/fairdent/saveRecojoPremio',
    formData
  );
  console.log(response);
  yield put(closeAllSaveRetiro());
  yield call(getPacientes(''));
}

function* getPacientesRetiro({ payload }) {
  const { search } = payload;
  const response = yield call(
    apiCall,
    'POST',
    'app/fairdent/getDataRetiros',
    search
  );
  yield put(setAllPatientsRetiro(response.retiros));
}

function* saveRetiro({ payload }) {
  const { dataForm } = payload;
  const formData = new FormData();
  formData.append('idUser', dataForm.idUser);
  formData.append('idGestion', dataForm.idGestion);
  formData.append('fecha', dataForm.fecha);
  formData.append('descripcion', dataForm.descripcion);
  formData.append('evidencia', dataForm.evidencia);
  formData.append('monto', dataForm.monto);
  formData.append('monto_dcto', dataForm.monto_dcto);
  formData.append('monto_entrega', dataForm.monto_entrega);
  const response = yield call(
    apiCall,
    'POST',
    'app/fairdent/saveRetiro',
    formData
  );
  yield put(closeSaveRetiro(response));
  // yield put(getPacientesRetiro());
  // yield put(setResponse());
}

export function* watchGetPacientes() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(types.AppFairdent.GET_PATIENTS, getPacientes);
}

export function* watchGetPremios() {
  yield takeEvery(types.AppFairdent.OPEN_MODAL_PREMIOS, getPremiosPaciente);
}

export function* watchSaveRecojoPremio() {
  yield takeEvery(types.AppFairdent.SAVE_RECOJO_LOADING_TRUE, saveRecojoPremio);
}

export function* watchGetPacientesRetiro() {
  yield takeEvery(types.AppFairdent.GET_PATIENTS_RETIRO, getPacientesRetiro);
}
export function* watchSaveRetiro() {
  yield takeEvery(types.AppFairdent.SAVE_RETIRO_LOADING_TRUE, saveRetiro);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetPacientes),
    fork(watchGetPremios),
    fork(watchSaveRecojoPremio),
    fork(watchGetPacientesRetiro),
    fork(watchSaveRetiro),
  ]);
}
