import React, { useEffect, useState } from 'react';
import { Stage, Layer, Group, Rect } from 'react-konva';

const TextBox = ({ item, xPos, yPos, readOnly }) => {
  return (
    <>
      {readOnly ? (
        <Rect
          x={xPos ? xPos : item.x + 6}
          y={
            item.type === 1
              ? (item.name >= 51 && item.name <= 55) ||
                (item.name >= 61 && item.name <= 65)
                ? yPos
                  ? yPos
                  : item.y - 190
                : yPos
                ? yPos
                : item.y - 70
              : (item.name >= 81 && item.name <= 85) ||
                (item.name >= 71 && item.name <= 75)
              ? yPos
                ? yPos
                : item.y + 250
              : yPos
              ? yPos
              : item.y + 130
          }
          width={35}
          height={20}
          stroke="black"
        />
      ) : (
        <Rect
          x={item.x + 6}
          y={
            item.type === 1
              ? (item.name >= 51 && item.name <= 55) ||
                (item.name >= 61 && item.name <= 65)
                ? item.y - 190
                : item.y - 70
              : (item.name >= 81 && item.name <= 85) ||
                (item.name >= 71 && item.name <= 75)
              ? item.y + 250
              : item.y + 130
          }
          width={35}
          height={20}
          stroke="black"
        />
      )}
    </>
  );
};

export default TextBox;
