import React, { useEffect, Fragment } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  Badge,
} from 'reactstrap';
import ThumbnailImage from '../../../../../components/cards/ThumbnailImage';
import { useSelector } from 'react-redux';
import moment from 'moment';

const PlanTrabajoFin = ({ planTratamiento, setPlanTratamiento }) => {
  const { planTrabajoFin } = useSelector(
    (state) => state.esp_paciente_actualTratam
  );

  useEffect(() => {
    setPlanTratamiento(planTrabajoFin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planTrabajoFin]);

  const onHover = (id) => {
    setPlanTratamiento((tratamiento) => {
      return tratamiento.map((item) => {
        return item.id === id
          ? {
              ...item,
              active: true,
            }
          : {
              ...item,
              active: false,
            };
      });
    });
  };

  const onHoverTooth = (id) => {
    setPlanTratamiento((tratamiento) => {
      return tratamiento.map((item) => {
        return item.active
          ? {
              ...item,
              detalles: item.detalles.map((element) => {
                return element.id === id
                  ? {
                      ...element,
                      active: true,
                    }
                  : {
                      ...element,
                      active: false,
                    };
              }),
            }
          : item;
      });
    });
  };

  const handleChangeObs = (e) => {
    setPlanTratamiento((tratamiento) => {
      return tratamiento.map((item) => {
        const newDetalles = item.detalles.map((element) => {
          return element.active
            ? {
                ...element,
                conclusion: e.target.value,
                trabajo: e.target.value.length > 0,
              }
            : element;
        });
        const restante = newDetalles.filter((data) => data.trabajo === false);

        return item.active
          ? {
              ...item,
              detalles: newDetalles,
              completado: restante.length === 0,
              cantidadRest: restante.length,
            }
          : item;
      });
    });
  };

  return (
    <Container>
      <Row>
        <Col sm={5}>
          {planTratamiento.map((item, index) => {
            return (
              <Fragment key={index}>
                <Card
                  id={`tratam_${index}`}
                  style={{ cursor: 'pointer' }}
                  className={`d-flex flex-row mb-4 ${
                    item.active ? 'border-success' : ''
                  }`}
                  onMouseEnter={() => onHover(item.id)}
                >
                  <div className="d-flex">
                    <ThumbnailImage
                      rounded
                      small
                      src={
                        require(`../../../../../assets/images/odontograma/${item.img}.png`)
                          .default
                      }
                      alt="profile"
                      className="m-4"
                    />
                  </div>
                  <div className=" d-flex flex-grow-1 min-width-zero">
                    <CardBody className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                      <div className="min-width-zero">
                        <div>
                          <CardSubtitle className="truncate mb-1">
                            {item.title}
                          </CardSubtitle>
                        </div>
                        <CardText className="text-muted text-small mb-2">
                          Diente pza.{' '}
                          {item.detalles.map(
                            (data) => `${data.diente_pieza}, `
                          )}
                        </CardText>
                        <div className="w-15 w-sm-100">
                          <Badge
                            color={item.completado ? 'success' : 'danger'}
                            pill
                          >
                            {item.completado
                              ? `Completado`
                              : `Falta completar ${item.cantidadRest} piezas`}
                          </Badge>
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </Card>
                <UncontrolledTooltip
                  placement="right"
                  target={`tratam_${index}`}
                >
                  Seleccionar tratamiento
                </UncontrolledTooltip>
              </Fragment>
            );
          })}
        </Col>
        {planTratamiento.map((item, index) => {
          return (
            <Fragment key={index}>
              {item.active && (
                <Col>
                  <h5>{item.title}</h5>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="w-100 d-flex flex-wrap justify-content-center mb-5">
                      {item.detalles.map((element, index2) => {
                        return (
                          <Fragment key={element.id}>
                            <div
                              id={`diente_${element.id}_${index2}`}
                              style={{
                                cursor: 'pointer',
                                backgroundColor: element.trabajo ? 'green' : '',
                                color: element.trabajo ? 'white' : 'black',
                              }}
                              className={`d-flex flex-column justify-content-center align-items-center border p-2 mr-2 ${
                                element.active ? 'border-success' : ''
                              }`}
                              onMouseEnter={() => onHoverTooth(element.id)}
                            >
                              <i className="fas fa-tooth fa-2x" />
                              <small>Pza. {element.diente_pieza}</small>
                            </div>
                            <UncontrolledTooltip
                              placement="top"
                              target={`diente_${element.id}_${index2}`}
                            >
                              Seleccionar pza. {element.diente_pieza}
                            </UncontrolledTooltip>
                          </Fragment>
                        );
                      })}
                    </div>
                    {item.detalles.map((element, index2) => {
                      return (
                        <Fragment key={index2}>
                          {element.active && (
                            <div className="w-75">
                              <h5>SELECCIONADO: Pza {element.diente_pieza}</h5>
                              <div>
                                <small>
                                  Pronostico de sesiones: {element.sesiones}
                                </small>
                                <br />
                                <small>
                                  Rango de fechas:{' '}
                                  {moment(element.fecha_inicio).format(
                                    'YYYY-MM-DD'
                                  )}
                                  {' hasta '}
                                  {moment(element.fecha_fin).format(
                                    'YYYY-MM-DD'
                                  )}
                                </small>
                              </div>
                              <FormGroup className="mt-3">
                                <Label className="form-group has-float-label">
                                  <span>Ingrese alguna observación</span>
                                  <Input
                                    name="conclusion"
                                    type="textarea"
                                    value={element.conclusion}
                                    onChange={(e) => handleChangeObs(e)}
                                    placeholder="observacion"
                                  />
                                </Label>
                              </FormGroup>
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </Col>
              )}
            </Fragment>
          );
        })}
      </Row>
    </Container>
  );
};

export default PlanTrabajoFin;
