import { types } from '../../../actions';

const INIT_STATE = {
  selectedTabCategory: 'ingresosClinica',
  loadingSelectedCategory: false,
  selectedTabCategoryTitle: 'INGRESOS CLINICA',
  selectedTypeComprob: 'TODOS',
  loadingSelectedTypeComprob: false,
  categoryItems: [],
  comprobanteTicket: [],
  comprobanteEgresos: [],
  comprobanteBoleta: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.admin.balanceSunat.diario.selectedTabCategory: {
      const category = action.payload.data;
      let title = 'INGRESOS CLINICA';
      switch (category) {
        case 'ingresosClinica':
          title = 'INGRESOS CLINICA';
          break;
        case 'egresosClinica':
          title = 'EGRESOS CLINICA';
          break;
        case 'ingresosLgst':
          title = 'INGRESOS LOGISTICA';
          break;
        case 'egresosLgst':
          title = 'EGRESOS LOGISTICA';
          break;
        default:
          break;
      }
      return {
        ...state,
        selectedTabCategory: category,
        selectedTabCategoryTitle: title,
        loadingSelectedCategory: false,
      };
    }
    case types.admin.balanceSunat.diario.selectedTabCategorySuccess: {
      const { dia, data } = action.payload;
      const items = [
        {
          icon: 'simple-icon-trophy',
          name: 'Meta',
          value: parseFloat(dia.montoMeta).toFixed(2),
          entidad: 'CLINICA',
        },
        {
          icon: 'iconsminds-basket-quantity',
          name: 'Acumulado',
          value: data.acumulacion,
          entidad: 'LOGISTICA',
        },
        {
          icon: 'simple-icon-reload',
          name: 'Restante',
          value: parseFloat(dia.montoMeta),
          entidad: 'LOGISTICA',
        },
      ];
      return {
        ...state,
        loadingSelectedCategory: false,
        categoryItems: items,
      };
    }
    case types.admin.balanceSunat.diario.selectedtypeComprob: {
      const { data } = action.payload;
      return {
        ...state,
        selectedTypeComprob: data,
        comprobanteTicket: [],
        loadingSelectedTypeComprob: true,
      };
    }
    case types.admin.balanceSunat.diario.selectedtypeComprobSuccess: {
      const { response } = action.payload;
      const { comprobantes } = response;
      return {
        ...state,
        comprobanteTicket: comprobantes,
        loadingSelectedTypeComprob: false,
      };
    }
    case types.admin.balanceSunat.categoria.ganancia.getSumComprobSunat: {
      return { ...state };
    }
    case types.admin.balanceSunat.categoria.ganancia
      .getSumComprobSunatSuccess: {
      return { ...state };
    }
    default:
      return { ...state };
  }
};
