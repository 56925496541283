import { all } from 'redux-saga/effects';
import authSagas from './Auth/saga';
import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import systemConf from './PatientData/saga';
import AplicacionSagas from './Aplication/AppFairdent/saga';
import EspecialistaSagas from './especialista/Sagas';
import mesaPartesSagas from './mesaPartes/saga';
import Admin from './Admin/BalanceSunat/saga';
import Mantenimiento from './Admin/Mantenimiento/saga';
import Asistente from './Asistente/saga';
import recursosHumanos from './recursosHumanos/sagas';

export default function* rootSaga() {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    systemConf(),
    AplicacionSagas(),
    EspecialistaSagas(),
    mesaPartesSagas(),
    Admin(),
    Mantenimiento(),
    Asistente(),
    recursosHumanos(),
  ]);
}
