import { types } from '../../../../actions';
import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import { apiCall } from '../../../../../config/axiosApiCallWrapper';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  getTicketComprobSuccess,
  sendTickesConvertSuccess,
  getTicketComprob,
} from './actions';

const MySwal = withReactContent(Swal);

function* sendTickesConvert({ payload }) {
  try {
    const { nuevoArreglo, idUser, dia } = payload;
    const idSucursal = yield select((state) => state.Auth.data.sucursal_id);
    const response = yield call(
      apiCall,
      'POST',
      'asistente/caja/saveMultiBoletaSunat',
      {
        nuevoArreglo,
        idUser,
      }
    );
    if (response.success) {
      yield put(sendTickesConvertSuccess());
      yield put(getTicketComprob(idSucursal, dia));
      MySwal.fire({
        icon: 'success',
        title: '¡Enviado Correctamente!',
        text: 'Ya se envio todos los tickets seleccionado',
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* getComprobantes({ payload }) {
  try {
    const { idSucursal, dia } = payload;
    const response = yield call(
      apiCall,
      'POST',
      'admin/balanceSunat/getComprobantesTicketFecha',
      {
        idSucursal,
        dia,
      }
    );
    if (response.success) {
      yield put(getTicketComprobSuccess(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* sendBoletaSunat({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(
      apiCall,
      'POST',
      'admin/balanceSunat/sendBoletaSunat',
      {
        data,
      }
    );
    yield console.log(response);
  } catch (err) {
    console.log(err);
  }
}

export function* watchSendTickets() {
  yield takeLatest(
    types.admin.balanceSunat.categoria.ganancia.sendTicketLoading,
    sendTickesConvert
  );
}

export function* watchGetComprobantes() {
  yield takeLatest(
    types.admin.balanceSunat.categoria.ganancia.getComprobantes,
    getComprobantes
  );
}

export function* watchSendBoletaSunat() {
  yield takeLatest(
    types.admin.balanceSunat.categoria.ganancia.send_register_sunat,
    sendBoletaSunat
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchSendTickets),
    fork(watchGetComprobantes),
    fork(watchSendBoletaSunat),
  ]);
}
