import React from 'react';

const Context = ({
  position,
  payload,
  onOptionSelected,
  handleOnMouseLeave,
}) => {
  const handleOptionSelected = (option, type) => () =>
    onOptionSelected(option, type);

  return (
    <div
      className="react-contextmenu react-contextmenu--visible"
      style={{
        position: 'fixed',
        left: position.x - 15,
        top: position.y - 15,
        opacity: 1,
        pointerEvents: 'auto',
        boxShadow: '0 2px 5px 0 #bfbfbf',
        borderRadius: 3,
      }}
      onMouseLeave={() => handleOnMouseLeave(payload)}
    >
      <div
        className="react-contextmenu-item"
        onClick={handleOptionSelected(payload, 'SELECT')}
      >
        <i className="simple-icon-docs" /> <span>Seleccionar</span>
      </div>
      <div
        className="react-contextmenu-item"
        onClick={handleOptionSelected(payload, 'DELETE')}
      >
        <i className="simple-icon-trash" /> <span>Eliminar</span>
      </div>
    </div>
  );
};

export default Context;
