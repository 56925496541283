import { types } from '../../../actions';
import { uniqueId } from '../../../../helpers/Utils';

const initialState = {
  modalPresupuesto: false,
  typesRadiografia: [],
  loadingTypesRadio: false,
  selectedtypesRadio: [],
  loadingPresupuesto: false,
  currentPresupuesto: { detalles: [] },
  modalDeleteRadio: false,
  modaleditRadio: false,
  modalRadioView: false,
  gallery: [],
  galleryTypes: [],
  loadingGallery: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.especialista.paciente.radiografia.openModalPresupuesto: {
      const presupuesto = state.currentPresupuesto.detalles;
      const { clear } = action.payload;
      const custom = clear
        ? []
        : presupuesto.map((item) => {
            return {
              id: uniqueId(),
              radioId: item.id,
              tipo: item.tipoRadiografia,
              precio: item.precio,
              cantidad: item.cantidad,
              total: item.total,
              temporal: false,
            };
          });

      return {
        ...state,
        modalPresupuesto: true,
        loadingTypesRadio: true,
        selectedtypesRadio: custom,
      };
    }
    case types.especialista.paciente.radiografia.getTypesRadioSuccess: {
      return {
        ...state,
        typesRadiografia: action.payload.data,
        loadingTypesRadio: false,
      };
    }
    case types.especialista.paciente.radiografia.closeModalPresupuesto: {
      return {
        ...state,
        modalPresupuesto: false,
      };
    }
    case types.especialista.paciente.radiografia.addSelectedtypesRadio: {
      const { data } = action.payload;
      const newArr = state.typesRadiografia.reduce(
        (acumulator, current) => [...acumulator, ...current.detalles],
        []
      );
      const findRadio = newArr.find((item) => {
        return parseInt(item.id) === parseInt(data.radiografia);
      });
      let addedRadio = [];
      if (findRadio) {
        addedRadio = [
          ...state.selectedtypesRadio,
          {
            id: uniqueId(),
            radioId: findRadio.id,
            tipo: findRadio.nombre,
            precio: findRadio.precio,
            cantidad: 1,
            total: findRadio.precio,
            temporal: true,
          },
        ];
      }
      return {
        ...state,
        selectedtypesRadio: addedRadio,
      };
    }
    case types.especialista.paciente.radiografia.deleteSelectedtypesRadio: {
      const { data } = action.payload;
      const newArr = state.selectedtypesRadio.filter(
        (item) => item.id !== data.id
      );
      return {
        ...state,
        selectedtypesRadio: newArr,
      };
    }
    case types.especialista.paciente.radiografia.savePresupuesto: {
      return {
        ...state,
      };
    }
    case types.especialista.paciente.radiografia.savePresupuestoSuccess: {
      return {
        ...state,
        selectedtypesRadio: [],
        modalPresupuesto: false,
      };
    }
    case types.especialista.paciente.radiografia.getCurrentPres: {
      return {
        ...state,
        loadingPresupuesto: true,
      };
    }
    case types.especialista.paciente.radiografia.getCurrentPresSuccess: {
      const { data } = action.payload;
      return {
        ...state,
        loadingPresupuesto: false,
        currentPresupuesto: data,
      };
    }
    case types.especialista.paciente.radiografia.openModalDeleteRadio: {
      const { data } = action.payload;
      const presupuesto = [data].map((item) => {
        return {
          id: uniqueId(),
          radioId: item.id,
          tipo: item.tipoRadiografia,
          precio: item.precio,
          cantidad: item.cantidad,
          total: item.total,
          temporal: false,
        };
      });
      return {
        ...state,
        modalDeleteRadio: true,
        selectedtypesRadio: presupuesto,
      };
    }
    case types.especialista.paciente.radiografia.closeModalDeleteRadio: {
      return {
        ...state,
        modalDeleteRadio: false,
        selectedtypesRadio: [],
      };
    }
    case types.especialista.paciente.radiografia.requestDeleteRadio: {
      return {
        ...state,
      };
    }
    case types.especialista.paciente.radiografia.requestDeleteRadioSuccess: {
      return {
        ...state,
        modalDeleteRadio: false,
      };
    }
    case types.especialista.paciente.radiografia.openModalEditRadio: {
      const { data } = action.payload;
      const presupuesto = [data].map((item) => {
        return {
          id: uniqueId(),
          radioId: item.id,
          tipo: item.tipoRadiografia,
          precio: item.precio,
          cantidad: item.cantidad,
          total: item.total,
          temporal: false,
        };
      });

      return {
        ...state,
        modaleditRadio: true,
        selectedtypesRadio: presupuesto,
      };
    }
    case types.especialista.paciente.radiografia.closeModalEditRadio: {
      return {
        ...state,
        modaleditRadio: false,
        selectedtypesRadio: [],
      };
    }
    case types.especialista.paciente.radiografia.editSelectedtypesRadio: {
      const { value, item } = action.payload;
      const radiografia = state.selectedtypesRadio.map((element) => {
        return element.id === item.id
          ? {
              ...element,
              cantidad: parseInt(value || 0) < 0 ? 1 : value,
              total: element.precio * (parseInt(value || 0) < 0 ? 1 : value),
            }
          : element;
      });

      return {
        ...state,
        selectedtypesRadio: radiografia,
      };
    }
    case types.especialista.paciente.radiografia.requestEditRadio: {
      return {
        ...state,
      };
    }
    case types.especialista.paciente.radiografia.requestEditRadioSuccess: {
      return {
        ...state,
        modaleditRadio: false,
      };
    }
    case types.especialista.paciente.radiografia.openModalRadioView: {
      return {
        ...state,
        modalRadioView: true,
        loadingGallery: true,
      };
    }
    case types.especialista.paciente.radiografia.closeModalRadioView: {
      return {
        ...state,
        modalRadioView: false,
        loadingGallery: false,
      };
    }
    case types.especialista.paciente.radiografia.radioViewSuccess: {
      const { data } = action.payload;
      return {
        ...state,
        gallery: data.radiografia,
        galleryTypes: data.presupuestoTypes,
        loadingGallery: false,
      };
    }
    default:
      return state;
  }
};
