import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';

const FraccionarForm = () => {
  const { selectedPacienteTicket } = useSelector(
    (state) => state.admin.balanceSunat.conversion
  );
  const initialVal = {};

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Formik
              initialValues={initialVal}
              validate={() => {}}
              onSubmit={() => {}}
            >
              {() => (
                <Form className="av-tooltip tooltip-label-bottom">
                  <Row>
                    <Col>
                      <h4 className="text-muted text-center mb-4">
                        Has superado el maximo de S/. 700.00 con un total de S/.
                        {` ${selectedPacienteTicket.sumatoria}`}.00
                      </h4>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FraccionarForm;
