import React from 'react';
import '../assets/css/Loader.css';
import { Player } from '@lottiefiles/react-lottie-player';
import Warrior from '../assets/animation/toothwarrior.json';

const Loarder = ({ message, width }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <Player
        autoplay
        loop
        src={Warrior}
        style={{
          height: width ? `${width}rem` : '12rem',
          width: width ? `${width}rem` : '12rem',
        }}
      />
      {message && (
        <h5 className="text-primary">
          <strong>{message}</strong>
        </h5>
      )}
    </div>
  );
};

export default Loarder;
