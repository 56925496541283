import { drawDamage } from './Damages';
import { damageSet } from '../Utils/Constants';

export const getData = (odontogram) => {
  let tempOdont = [];
  odontogram.forEach((element) => {
    let check = false;
    element.checkBoxes.forEach((item) => {
      if (item.color.length !== 0) {
        check = true;
      }
    });
    if (element.damages.length !== 0 || element.textBoxes.length !== 0) {
      tempOdont.push({
        id: element.id,
        damages: element.damages,
        checkBoxes: check ? element.checkBoxes : [],
        textBoxes: element.textBoxes,
      });
    } else if (check) {
      tempOdont.push({
        id: element.id,
        damages: element.damages,
        checkBoxes: element.checkBoxes,
        textBoxes: element.textBoxes,
      });
    }
  });
  return JSON.stringify(tempOdont);
  // console.log(tempOdont);
  // localStorage.setItem("odontograma", JSON.stringify(tempOdont))
  // console.log(JSON.stringify(tempOdont));
};

export const getDataFull = (odontogram) => {
  let tempOdont = [];
  odontogram.forEach((element) => {
    if (element.damages.length !== 0) {
      tempOdont.push(element);
    }
  });
  return tempOdont;
};

export const textDamages = (odontogram, id_cita) => {
  const odontograma = JSON.parse(getData(odontogram));
  let tratamientos = [];
  odontograma.forEach((item) => {
    item.damages.forEach((child) => {
      if (child.id_cita === id_cita) {
        const str = child.id;
        const res = str.split('_');
        tratamientos.push(res[0]);
      }
    });
  });
  const result = tratamientos.filter((item, index) => {
    return tratamientos.indexOf(item) === index;
  });
  tratamientos = result;
  const dientes = [];

  tratamientos.forEach((item, index) => {
    dientes[index] = [];
    odontograma.forEach((child) => {
      child.damages.forEach((childPlus) => {
        if (childPlus.id_cita === id_cita) {
          const str = childPlus.id;
          const res = str.split('_');
          if (item === res[0]) {
            dientes[index].push(res[2]);
          }
        }
      });
    });
  });

  tratamientos.forEach((item, index) => {
    damageSet.forEach((child) => {
      if (child.name === parseInt(item)) {
        tratamientos[index] = `${child.tip}: ${dientes[index].toString()}  `;
      }
    });
  });

  return tratamientos.toString();
};

export const textDamagesGroup = (odontogram) => {
  let tratamiento = odontogram;
  let textTratamiento = [];

  tratamiento.forEach((element) => {
    const number = element.detalles
      .filter((data) => data.trabajo)
      .map((dtl) => dtl.name)
      .toString();
    if (element.selected > 0)
      textTratamiento.push(` ${element.title}: ${number} `);
  });

  return textTratamiento.toString();
};

export const getDamageGroup = (odontogram) => {
  const odontograma = getDataFull(odontogram);
  let tratamientos = [];
  odontograma.forEach((item) => {
    item.damages.forEach((child) => {
      const str = child.id;
      const res = str.split('_');
      tratamientos.push(res[0]);
    });
  });
  const result = tratamientos.filter((item, index) => {
    return tratamientos.indexOf(item) === index;
  });

  tratamientos = result;
  const dientes = [];

  tratamientos.forEach((item, index) => {
    dientes[index] = [];
    odontograma.forEach((child) => {
      child.damages.forEach((childPlus) => {
        const str = childPlus.id;
        const res = str.split('_');
        if (item === res[0]) {
          dientes[index].push({
            ...child,
            damages: [childPlus],
          });
        }
      });
    });
  });

  let tratamFinal = [];

  tratamientos.forEach((item, index) => {
    damageSet.forEach((child) => {
      if (child.name === parseInt(item)) {
        tratamFinal[index] = {
          id: child.name,
          title: child.tip,
          damage: child.name,
          img: child.image,
          image: child.image,
          total: dientes[index].length,
          status: 'seleccione',
          statusColor: 'danger',
          selected: 0,
          active: false,
          trabajo: false,
          detalles: dientes[index].map((element, index) => {
            return {
              ...element,
              sesiones: '0',
              fecha_inicio: '',
              fecha_fin: '',
              observaciones: '',
              active: false,
              trabajo: false,
            };
          }),
        };
      }
    });
  });

  return tratamFinal;
};

export const startData = (setOdontogram, adultSup, result) => {
  let newArr = result;
  result.forEach((element, index) => {
    const find = adultSup.find((item) => item.id === element.id);
    let newArr2 = adultSup.slice();
    let index2 = newArr2.indexOf(find);
    if (index2 !== -1) {
      element.damages.forEach((item, indexChild) => {
        newArr[index]['damages'][indexChild] = drawDamage(
          newArr[index]['damages'][indexChild].id,
          newArr[index]['damages'][indexChild].damage,
          newArr2[index2].x,
          newArr2[index2].y,
          newArr[index]['damages'][indexChild].type,
          newArr[index]['damages'][indexChild].breakStart,
          newArr[index]['damages'][indexChild].breakEnd,
          newArr[index]['damages'][indexChild].point,
          newArr[index]['damages'][indexChild].id_cita,
          newArr[index]['damages'][indexChild].date,
          newArr[index]['damages'][indexChild].especialista,
          newArr[index]['damages'][indexChild].especialista_id,
          newArr[index]['damages'][indexChild].asunto
        );
      });
      if (element.checkBoxes.length !== 0) {
        newArr2[index2]['checkBoxes'] = element.checkBoxes.map((item) => {
          return {
            ...item,
            color: Array.isArray(item.color) ? item.color : [item.color],
          };
        });
      }
      newArr2[index2]['damages'] = element.damages;
      newArr2[index2]['textBoxes'] = element.textBoxes;
    }
  });
  setOdontogram(adultSup);
};
