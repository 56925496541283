import { all, fork, takeLatest, call, put } from 'redux-saga/effects';
import { apiCall } from '../../../config/axiosApiCallWrapper';
import { types } from '../../actions';
import {
  setTramitesUser,
  setArchivosSeguimiento,
  setDataHistorial,
} from './Actions';

function* getTramitesUser({ payload }) {
  const { data, page } = payload;
  try {
    const response = yield call(
      apiCall,
      'POST',
      `principal/mesaPartes/getTramitesUser?page=${page}`,
      { data }
    );
    if (response.success) {
      yield put(setTramitesUser(response.tramites));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getArchivosSeguimiento({ payload }) {
  try {
    const response = yield call(
      apiCall,
      'POST',
      'principal/mesaPartes/getArchivosSeguimiento',
      { payload }
    );
    if (response.success) {
      yield put(setArchivosSeguimiento(response));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getDataHistorial({ payload }) {
  try {
    const response = yield call(
      apiCall,
      'POST',
      'principal/mesaPartes/getDataHistorial',
      { payload }
    );
    if (response.success) {
      yield put(setDataHistorial(response));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchTramitesUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(
    types.MesaPartes.Seguimiento.GET_TRAMITES_USER_LOADING,
    getTramitesUser
  );
}

export function* watchSeguimientoArchivos() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(
    types.MesaPartes.Seguimiento.OPEN_MODAL_ARCHIVOS,
    getArchivosSeguimiento
  );
}

export function* watchHistorialData() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(
    types.MesaPartes.Seguimiento.OPEN_MODAL_SEGUIMIENTO,
    getDataHistorial
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchTramitesUser),
    fork(watchSeguimientoArchivos),
    fork(watchHistorialData),
  ]);
}
