// eslint-disable-next-line import/no-cycle
import {
  CHANGE_LOCALE,
  LOADER_GENERAL_ENABLED,
  LOADER_GENERAL_DISABLED,
} from '../actions';
import { setCurrentLanguage } from '../../helpers/Utils';

// eslint-disable-next-line import/prefer-default-export
export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};

export const initLoading = () => ({
  type: LOADER_GENERAL_ENABLED,
});

export const stopLoading = () => ({
  type: LOADER_GENERAL_DISABLED,
});
