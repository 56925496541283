import { types } from '../actions';

export const getStateConfig = (token, sucursalId) => ({
  type: types.systemConf.GET_STATE_CONFIG,
  payload: { token, sucursalId },
});

export const OpenCaja = () => ({
  type: 'OPEN_CAJA',
});

export const setIdCaja = (data) => ({
  type: 'SET_IDCAJA',
  payload: data,
});

export const closeCaja = () => ({
  type: 'CLOSE_CAJA',
});
