import { types } from '../actions';

export const getTramitesUserLoading = (data, page, id) => ({
  type: types.MesaPartes.GET_TRAMITES_USER_LOADING,
  payload: { data, page, id },
});

export const setTramitesUser = (data) => ({
  type: types.MesaPartes.SET_TRAMITES_USER,
  payload: data,
});

export const openModalDetalles = (data, id) => ({
  type: types.MesaPartes.OPEN_MODAL_DETALLES,
  payload: { data, id },
});

export const closeModalDetalles = () => ({
  type: types.MesaPartes.CLOSE_MODAL_DETALLES,
});

export const setDataDetalles = (data) => ({
  type: types.MesaPartes.SET_DATA_DETALLES,
  payload: data,
});

export const openModalArchivos = (data) => ({
  type: types.MesaPartes.OPEN_MODAL_ARCHIVOS,
  payload: data,
});

export const closeModalArchivos = () => ({
  type: types.MesaPartes.CLOSE_MODAL_ARCHIVOS,
});

export const setArchivosSeguimiento = (data) => ({
  type: types.MesaPartes.SET_ARCHIVOS_SEGUIMIENTO,
  payload: data,
});

export const openModalSeguimiento = (data) => ({
  type: types.MesaPartes.OPEN_MODAL_SEGUIMIENTO,
  payload: data,
});

export const closeModalSeguimiento = () => ({
  type: types.MesaPartes.CLOSE_MODAL_SEGUIMIENTO,
});

export const setDataHistorial = (data) => ({
  type: types.MesaPartes.SET_DATA_HISTORIAL,
  payload: data,
});

export const openModalAcciones = (data) => ({
  type: types.MesaPartes.OPEN_MODAL_ACCIONES,
  payload: data,
});

export const closeModalAcciones = () => ({
  type: types.MesaPartes.CLOSE_MODAL_ACCIONES,
});

export const loadingSaveAccion = (data) => ({
  type: types.MesaPartes.LOADING_SAVE_ACCION,
  payload: data,
});

export const setOpcionesDerivar = (data) => ({
  type: types.MesaPartes.SET_OPCIONES_DERIVAR,
  payload: data,
});

export const sucessSaveAccion = () => ({
  type: types.MesaPartes.SUCCESS_SAVE_ACCION,
});

export const loadingTipoDocument = (payload) => ({
  type: types.MesaPartes.TIPO_DOCUMENT_LOADING,
  payload,
});

export const successTipoDocument = (payload) => ({
  type: types.MesaPartes.TIPO_DOCUMENT_SUCCESS,
  payload,
});

export const selectedFilterFunction = (data = { numero: '' }) => ({
  type: types.MesaPartes.selectedFilter,
  payload: { data },
});

export const setVistoArchivos = (payload) => ({
  type: types.MesaPartes.Seguimiento.SET_VISTO_ARCHIVOS,
  payload,
});
