import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import {
  getAllRadiografiasSuccess,
  subirRadiografiaByCitaSuccess,
  openModalRadiografia,
} from './actions';
import { types } from '../../../actions';
import { apiCall } from '../../../../config/axiosApiCallWrapper';
import { objectToFormData } from '../../../../helpers/Utils';

function* getAllRadiografias({ payload }) {
  try {
    const id_cita = payload.data;
    const id_paciente = yield select(
      (state) => state.PatientData.id_paciente.id_paciente
    );
    const response = yield call(
      apiCall,
      'POST',
      'especialista/paciente/radiografias/getRadiografiasByCita',
      { id_cita, id_paciente }
    );
    yield put(getAllRadiografiasSuccess(response));
  } catch (err) {
    console.log(err);
  }
}

function* subirRadioByCita({ payload }) {
  try {
    const { data } = payload;
    const id_paciente = yield select(
      (state) => state.PatientData.id_paciente.id_paciente
    );
    const idUser = yield select((state) => state.Auth.data.id);
    const { selectedTypeRadio, idCitaSelected } = yield select(
      (state) => state.asistente.paciente.radiografias
    );
    const tempData = {
      ...data,
      idUser,
      idCitaSelected,
      id_paciente,
      ...selectedTypeRadio,
    };

    const formData = objectToFormData(tempData);
    const response = yield call(
      apiCall,
      'POST',
      'especialista/paciente/radiografias/uploadRadiografia',
      formData
    );
    yield put(subirRadiografiaByCitaSuccess(response));
    yield put(openModalRadiografia(idCitaSelected));
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetReciboCita() {
  yield takeLatest(
    types.asistente.paciente.radiografia.openModalRadiografia,
    getAllRadiografias
  );
}

export function* watchSubirRadioByCita() {
  yield takeLatest(
    types.asistente.paciente.radiografia.subirRadiografiaByCita,
    subirRadioByCita
  );
}

export default function* rootSaga() {
  yield all([fork(watchGetReciboCita), fork(watchSubirRadioByCita)]);
}
