const odontDirection = (number) => {
  let direction = 0;
  if ((number >= 11 && number <= 28) || (number >= 51 && number <= 65)) {
    direction = 1;
  } else if ((number >= 31 && number <= 48) || (number >= 71 && number <= 85)) {
    direction = 0;
  }
  return direction;
};

export const getSurfaces = (number) => {
  let surface = 4;
  if (
    (number <= 13 && number >= 11) ||
    (number >= 21 && number <= 23) ||
    (number <= 43 && number >= 41) ||
    (number >= 31 && number <= 33) ||
    (number <= 53 && number >= 51) ||
    (number >= 61 && number <= 63) ||
    (number <= 83 && number >= 81) ||
    (number >= 71 && number <= 73)
  ) {
    surface = 4;
  } else {
    surface = 5;
  }

  return surface;
};

const getSupInf = (number) => {
  let superficie = 0;
  const value = 60;
  if (number >= 11 && number <= 28) {
    superficie = 30 + value;
  } else if (number >= 51 && number <= 65) {
    superficie = 180 + value;
  } else if (number >= 31 && number <= 48) {
    superficie = 540 + value;
  } else if (number >= 71 && number <= 85) {
    superficie = 390 + value;
  }
  return superficie;
};

const breakNumber = (item, posAdultSup) => {
  let number = posAdultSup;
  if (item === 28 || item === 65 || item === 75 || item === 38) {
    number = 0;
  } else {
    number++;
  }

  return number;
};

const isChild = (element) => {
  let number = 30;
  if ((element >= 51 && element <= 65) || (element >= 71 && element <= 85)) {
    number = 144;
  }

  return number;
};

const Odontogram = () => {
  const toothNumbers = [
    18,
    17,
    16,
    15,
    14,
    13,
    12,
    11,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    48,
    47,
    46,
    45,
    44,
    43,
    42,
    41,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    55,
    54,
    53,
    52,
    51,
    61,
    62,
    63,
    64,
    65,
    85,
    84,
    83,
    82,
    81,
    71,
    72,
    73,
    74,
    75,
  ];
  const spaceTooth = 38;
  let posAdultSup = 0;
  const surfaceDimen = 125;
  const teeth = toothNumbers.map((item) => {
    const direction = odontDirection(item);
    const surfaces = getSurfaces(item);
    const adultX = isChild(item);
    const startX =
      adultX + spaceTooth * posAdultSup + (surfaces === 4 ? 10 : 5);
    const adultYSup = getSupInf(item);
    const PosY = adultYSup;
    const imageObj = new Image();
    imageObj.src = require(`../Models/img/dentadura-${
      direction === 1 ? 'sup' : 'inf'
    }-${item}.png`).default;
    const newItem = {
      id: `tooth_${item}`,
      name: item,
      x: adultX + spaceTooth * posAdultSup,
      y: adultYSup,
      damages: [],
      checkBoxes:
        surfaces === 4
          ? [
              {
                id: `${item}_M`,
                color: [],
                active: false,
                hover: false,
                x: startX,
                y: PosY + 90 + 10 - (direction === 1 ? 0 : surfaceDimen),
                width: 10,
                height: 10,
              },
              {
                id: `${item}_D`,
                color: [],
                active: false,
                hover: false,
                x: startX + 10,
                y: PosY + 90 + 10 - (direction === 1 ? 0 : surfaceDimen),
                width: 10,
                height: 10,
              },
              {
                id: `${item}_V`,
                color: [],
                active: false,
                hover: false,
                x: startX + 5,
                y: PosY + 90 - (direction === 1 ? 0 : surfaceDimen),
                width: 10,
                height: 10,
              },
              {
                id: `${item}_L`,
                color: [],
                active: false,
                hover: false,
                x: startX + 5,
                y: PosY + 90 + 10 * 2 - (direction === 1 ? 0 : surfaceDimen),
                width: 10,
                height: 10,
              },
            ]
          : [
              {
                id: `${item}_M`,
                color: [],
                active: false,
                hover: false,
                x: startX,
                y: PosY + 90 + 10 - (direction === 1 ? 0 : surfaceDimen),
                width: 10,
                height: 10,
              },
              {
                id: `${item}_0`,
                color: [],
                active: false,
                hover: false,
                x: startX + 10,
                y: PosY + 90 + 10 - (direction === 1 ? 0 : surfaceDimen),
                width: 10,
                height: 10,
              },
              {
                id: `${item}_D`,
                color: [],
                active: false,
                hover: false,
                x: startX + 10 * 2,
                y: PosY + 90 + 10 - (direction === 1 ? 0 : surfaceDimen),
                width: 10,
                height: 10,
              },
              {
                id: `${item}_V`,
                color: [],
                active: false,
                hover: false,
                x: startX + 10,
                y: PosY + 90 - (direction === 1 ? 0 : surfaceDimen),
                width: 10,
                height: 10,
              },
              {
                id: `${item}_L`,
                color: [],
                active: false,
                hover: false,
                x: startX + 10,
                y: PosY + 90 + 10 * 2 - (direction === 1 ? 0 : surfaceDimen),
                width: 10,
                height: 10,
              },
            ],
      img: imageObj,
      textBoxes: '',
      hover: false,
      type: direction,
      rect: {
        height: 90,
        width: 40,
        x: adultX + spaceTooth * posAdultSup,
        y: adultYSup,
      },
      surfaces: surfaces,
    };
    posAdultSup = breakNumber(item, posAdultSup);
    return newItem;
  });

  return teeth;
};

export default Odontogram;
