import { types } from '../../actions';

export const openModalPremios = (data) => ({
  type: types.AppFairdent.OPEN_MODAL_PREMIOS,
  payload: data,
});

export const closeModalPremios = () => ({
  type: types.AppFairdent.CLOSE_MODAL_PREMIOS,
});

export const getAllPacientes = (search, token) => ({
  type: types.AppFairdent.GET_PATIENTS,
  payload: { search, token },
});

export const setAllPatientes = (data) => ({
  type: types.AppFairdent.SET_ALL_PATIENTS,
  payload: data,
});

export const setPremiosPaciente = (data) => ({
  type: types.AppFairdent.SET_PREMIOS_PACIENTE,
  payload: data,
});

export const openModalRecojo = (data) => ({
  type: types.AppFairdent.OPEN_MODAL_RECOJO,
  payload: data,
});

export const closeModalRecojo = () => ({
  type: types.AppFairdent.CLOSE_MODAL_RECOJO,
});

export const saveRecojoLoadingTrue = (payload) => ({
  type: types.AppFairdent.SAVE_RECOJO_LOADING_TRUE,
  payload,
});

export const saveRecojoLoadingFalse = () => ({
  type: types.AppFairdent.SAVE_RECOJO_LOADING_FALSE,
});

export const getAllPatientsRetiro = (search, token) => ({
  type: types.AppFairdent.GET_PATIENTS_RETIRO,
  payload: { search, token },
});

export const setAllPatientsRetiro = (data) => ({
  type: types.AppFairdent.SET_ALL_PATIENTS_RETIRO,
  payload: data,
});

export const closeAllSaveRetiro = () => ({
  type: types.AppFairdent.CLOSE_ALL_SAVE_RETIRO,
});

export const openModalRetiro = (data) => ({
  type: types.AppFairdent.OPEN_MODAL_RETIRO,
  payload: data,
});

export const closeModalRetiro = () => ({
  type: types.AppFairdent.CLOSE_MODAL_RETIRO,
});

export const saveRetiroLoadingTrue = (dataForm) => ({
  type: types.AppFairdent.SAVE_RETIRO_LOADING_TRUE,
  payload: { dataForm },
});

export const saveRetiroLoadingFalse = () => ({
  type: types.AppFairdent.SAVE_RETIRO_LOADING_FALSE,
});

export const closeSaveRetiro = () => ({
  type: types.AppFairdent.CLOSE_SAVE_RETIRO,
});

export const setResponse = (data) => ({
  type: types.AppFairdent.SET_RESPONSE,
  payload: data,
});
