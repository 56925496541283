import { types } from '../../../actions';

export const getSearchBoletaFactura = (data, page = 1) => ({
  type: types.admin.balanceSunat.sunat.getSearchBoletaFactura,
  payload: { data, page },
});

export const getSearchBoletaFacturaSuccess = (data) => ({
  type: types.admin.balanceSunat.sunat.getSearchBoletaFacturaSuccess,
  payload: { data },
});

export const openModalEditar = (item, subItem) => ({
  type: types.admin.balanceSunat.sunat.openModalEditar,
  payload: { item, subItem },
});

export const closeModalEditar = (data) => ({
  type: types.admin.balanceSunat.sunat.closeModalEditar,
  payload: { data },
});

export const buscarDocumento = (setFieldValue, doc, tipoDoc) => ({
  type: types.admin.balanceSunat.sunat.buscarDocumento,
  payload: { setFieldValue, doc, tipoDoc },
});

export const buscarDocumentoSuccess = (data) => ({
  type: types.admin.balanceSunat.sunat.buscarDocumentoSuccess,
  payload: { data },
});

export const editSelectedPacienteComprob = (value, item) => ({
  type: types.admin.balanceSunat.sunat.editSelectedPacienteComprob,
  payload: { value, item },
});

export const saveBoletaFactura = (values) => ({
  type: types.admin.balanceSunat.sunat.saveBoletaFactura,
  payload: { values },
});

export const saveBoletaFacturaSuccess = (values) => ({
  type: types.admin.balanceSunat.sunat.saveBoletaFacturaSuccess,
  payload: { values },
});

export const sendBoletaFacturaSunat = (values) => ({
  type: types.admin.balanceSunat.sunat.sendBoletaFacturaSunat,
  payload: { values },
});

export const sendBoletaFacturaSunatSuccess = (values) => ({
  type: types.admin.balanceSunat.sunat.sendBoletaFacturaSunatSuccess,
  payload: { values },
});
