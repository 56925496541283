import { types } from '../../../actions';

export const getSeachFilter = (data, page = 1) => ({
  type: types.admin.balanceSunat.conversion.getSeachFilter,
  payload: { data, page },
});

export const getSeachFilterSuccess = (data) => ({
  type: types.admin.balanceSunat.conversion.getSeachFilterSuccess,
  payload: { data },
});

export const setSelectecComprob = (data) => ({
  type: types.admin.balanceSunat.conversion.setSelectecComprob,
  payload: { data },
});

export const deleteSelectecComprob = (data) => ({
  type: types.admin.balanceSunat.conversion.deleteSelectecComprob,
  payload: { data },
});

export const openModalConvert = (values, selectedComprob) => ({
  type: types.admin.balanceSunat.conversion.openModalConvert,
  payload: { values, selectedComprob },
});

export const closeModalConvert = (data) => ({
  type: types.admin.balanceSunat.conversion.closeModalConvert,
  payload: { data },
});

export const buscarDocumento = (setFieldValue, doc, tipoDoc) => ({
  type: types.admin.balanceSunat.conversion.buscarDocumento,
  payload: { setFieldValue, doc, tipoDoc },
});

export const buscarDocumentoSuccess = (data) => ({
  type: types.admin.balanceSunat.conversion.buscarDocumentoSuccess,
  payload: { data },
});

export const editSelectedPacienteTicket = (value, item) => ({
  type: types.admin.balanceSunat.conversion.editSelectedPacienteTicket,
  payload: { value, item },
});

export const saveConvertTickets = (values) => ({
  type: types.admin.balanceSunat.conversion.saveConvertTickets,
  payload: { values },
});

export const saveConvertTicketsSuccess = (values) => ({
  type: types.admin.balanceSunat.conversion.saveConvertTicketsSuccess,
  payload: { values },
});
