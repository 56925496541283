import React from 'react';
import Logo from '../assets/logos/sos.png';

const LoaderIsoLogo = () => {
  return (
    <div className="loadscreen">
      <img src={Logo} className="logo mb-3" alt="Fairdent" />
      <div className="spinner-general" aria-busy="true">
        <span className="bubble1-general" />
        <span className="bubble2-general" />
        <span className="bubble3-general" />
      </div>
    </div>
  );
};

export default LoaderIsoLogo;
