import {
  GET_LOGO_CLINICA,
  GET_LOGO_CLINICA_SUCCESS,
  GET_LOGO_CLINICA_ERROR,
} from '../../actions';

export const loadLogoClinica = (idSucursal) => ({
  type: GET_LOGO_CLINICA,
  payload: idSucursal,
});
export const getLogoClinicaSuccess = (data) => ({
  type: GET_LOGO_CLINICA_SUCCESS,
  payload: data,
});
export const getLogoClinicaError = (dataerror) => ({
  type: GET_LOGO_CLINICA_ERROR,
  payload: dataerror,
});
