import NumerosALetras from '../../../../../components/NumerosALetras';
import { types } from '../../../../actions';

const INIT_STATE = {
  selectedTickets: [],
  tickets: [],
  loading: false,
  modalPreviewBoleta: false,
  currentSelectedBoleta: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.admin.balanceSunat.categoria.ganancia.SET_SELECTED_TICKET: {
      let tickets = state.selectedTickets;
      const item = action.payload.data;
      const find = tickets.find((data) => data.id === item.id);
      if (find) {
        tickets = tickets.filter((data) => data.id !== item.id);
      } else {
        tickets = [...tickets, item];
      }
      return { ...state, selectedTickets: tickets };
    }
    case types.admin.balanceSunat.categoria.ganancia.sendTicketLoading: {
      return { ...state, loading: true };
    }
    case types.admin.balanceSunat.categoria.ganancia.sendTicketSuccess: {
      return { ...state, loading: false, selectedTickets: [] };
    }
    case types.admin.balanceSunat.categoria.ganancia.getComprobantes: {
      return { ...state, loading: true };
    }
    case types.admin.balanceSunat.categoria.ganancia.getComprobantesSuccess: {
      const { response } = action.payload;
      const comprobantes = response.comprobantes.map((item) => {
        return {
          ...item,
          letras: NumerosALetras(item.tore_importe).toUpperCase(),
        };
      });
      return { ...state, loading: false, tickets: comprobantes };
    }
    case types.admin.balanceSunat.categoria.ganancia.send_register_sunat: {
      return { ...state };
    }
    case types.admin.balanceSunat.categoria.ganancia
      .send_register_sunat_success: {
      return { ...state };
    }
    case types.admin.balanceSunat.categoria.ganancia.openModalPreviewBoleta: {
      return {
        ...state,
        modalPreviewBoleta: true,
        currentSelectedBoleta: action.payload.data,
      };
    }
    case types.admin.balanceSunat.categoria.ganancia.closeModalPreviewBoleta: {
      return { ...state, modalPreviewBoleta: false };
    }
    default:
      return { ...state };
  }
};
