import { types } from '../../../actions';

export const setSelectedTab = (dia, data = 'ingresosClinica') => ({
  type: types.admin.balanceSunat.diario.selectedTabCategory,
  payload: { dia, data },
});

export const setSelectedTabSuccess = (dia, data) => ({
  type: types.admin.balanceSunat.diario.selectedTabCategorySuccess,
  payload: { dia, data },
});

export const setSelectedType = (dia, data = 'TODOS') => ({
  type: types.admin.balanceSunat.diario.selectedtypeComprob,
  payload: { data, dia },
});

export const setSelectedTypeSuccess = (dia, response, type) => ({
  type: types.admin.balanceSunat.diario.selectedtypeComprobSuccess,
  payload: { dia, response, type },
});

export const setCategoryItems = (data) => ({
  type: types.admin.balanceSunat.diario.setCategoryItems,
  payload: { data },
});
