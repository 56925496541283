import { types } from '../../../actions';

const initialState = {
  modalRadiografia: false,
  modalSubirRadio: false,
  loadingSubirRadio: false,
  loadingGallery: false,
  selectedTypeRadio: null,
  gallery: [],
  galleryTypes: [],
  idCitaSelected: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.asistente.paciente.radiografia.openModalRadiografia: {
      return {
        ...state,
        modalRadiografia: true,
        loadingGallery: true,
        idCitaSelected: action.payload.data,
      };
    }
    case types.asistente.paciente.radiografia.closeModalRadiografia: {
      return {
        ...state,
        modalRadiografia: false,
        gallery: [],
        galleryTypes: [],
        loadingGallery: false,
        idCitaSelected: null,
      };
    }
    case types.asistente.paciente.radiografia.getAllRadiografiasSuccess: {
      const { radiografia, presupuestoTypes } = action.payload.data;

      return {
        ...state,
        gallery: radiografia,
        galleryTypes: presupuestoTypes,
        loadingGallery: false,
      };
    }
    case types.asistente.paciente.radiografia.openModalSubirRadiografia: {
      const { data } = action.payload;
      return {
        ...state,
        modalSubirRadio: true,
        selectedTypeRadio: data,
      };
    }
    case types.asistente.paciente.radiografia.closeModalSubirRadiografia: {
      return {
        ...state,
        modalSubirRadio: false,
        selectedTypeRadio: null,
      };
    }
    case types.asistente.paciente.radiografia.subirRadiografiaByCita: {
      return {
        ...state,
        loadingSubirRadio: true,
      };
    }
    case types.asistente.paciente.radiografia.subirRadiografiaByCitaSuccess: {
      return {
        ...state,
        modalSubirRadio: false,
        loadingSubirRadio: false,
        selectedTypeRadio: null,
      };
    }
    default:
      return state;
  }
};
