import {
  GET_LOGO_CLINICA,
  GET_LOGO_CLINICA_SUCCESS,
  GET_LOGO_CLINICA_ERROR,
} from '../../actions';

const initialValues = {
  logo: 'fairdent',
  loading: false,
  status: null,
};
export default (state = initialValues, action) => {
  switch (action.type) {
    case GET_LOGO_CLINICA:
      return { ...state, loading: true };

    case GET_LOGO_CLINICA_SUCCESS:
      return {
        ...state,
        loading: false,
        logo: action.payload.logo,
        status: action.payload.success,
      };

    case GET_LOGO_CLINICA_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
