import { types } from '../../../actions';
import NumerosALetras from '../../../../components/NumerosALetras';
import FraccionarForm from '../../../../views/admin/balance/convertir/FraccionarForm';

const INIT_STATE = {
  comprobantes: { data: [] },
  loadigComprobantes: false,
  selectedComprob: [],
  modalConvert: false,
  selectedPacienteTicket: null,
  loadingBuscarDoc: false,
  stepsMaker: [],
  loadingSaveConvertTicket: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.admin.balanceSunat.conversion.getSeachFilter:
      return { ...state, loadigComprobantes: true };
    case types.admin.balanceSunat.conversion.getSeachFilterSuccess: {
      const { data } = action.payload;
      const comprobantesRes = data.comprobantes;
      const comprobanteSelected = state.selectedComprob;
      const difference = {
        ...comprobantesRes,
        data: comprobantesRes.data.map((item) => {
          return comprobanteSelected.find((select) => select.id === item.id)
            ? { ...item, isSelect: true }
            : item;
        }),
      };

      return {
        ...state,
        comprobantes: difference,
        loadigComprobantes: false,
      };
    }
    case types.admin.balanceSunat.conversion.setSelectecComprob: {
      const { data } = action.payload;
      let selectedComprobAux = state.selectedComprob;
      const findComprob = selectedComprobAux.find(
        (item) => item.id === data.id
      );
      if (findComprob) {
        selectedComprobAux = selectedComprobAux.filter(
          (item) => item.id !== data.id
        );
      } else {
        selectedComprobAux = [...selectedComprobAux, data];
      }
      const selectedTempComprobantes = {
        ...state.comprobantes,
        data: state.comprobantes.data.map((item) => {
          return item.id === data.id
            ? {
                ...item,
                isSelect: !item.isSelect,
              }
            : item;
        }),
      };
      return {
        ...state,
        comprobantes: selectedTempComprobantes,
        selectedComprob: selectedComprobAux,
      };
    }
    case types.admin.balanceSunat.conversion.deleteSelectecComprob: {
      const { data } = action.payload;
      let selectedComprobAux = state.selectedComprob;
      const findComprob = selectedComprobAux.find(
        (item) => item.id === data.id
      );
      if (findComprob) {
        selectedComprobAux = selectedComprobAux.filter(
          (item) => item.id !== data.id
        );
      }
      const selectedTempComprobantes = {
        ...state.comprobantes,
        data: state.comprobantes.data.map((item) => {
          return item.id === data.id
            ? {
                ...item,
                isSelect: false,
              }
            : item;
        }),
      };
      return {
        ...state,
        comprobantes: selectedTempComprobantes,
        selectedComprob: selectedComprobAux,
      };
    }
    case types.admin.balanceSunat.conversion.openModalConvert: {
      const { values, selectedComprob } = action.payload;

      const sumatoria = selectedComprob
        .filter((data) => data.idPaciente === values.idPaciente)
        .reduce(
          (accumulator, currentValue) =>
            parseFloat(accumulator) + parseFloat(currentValue.importe),
          0
        );

      const comprobantes = {
        ...values,
        letras: NumerosALetras(sumatoria).toUpperCase(),
        sumatoria,
        detalles: selectedComprob
          .filter((data) => data.idPaciente === values.idPaciente)
          .map((item) => ({
            ...item,
            letras: NumerosALetras(item.importe).toUpperCase(),
          })),
      };

      const stepMade = [
        sumatoria > 700
          ? {
              id: 'fraccionar',
              name: 'Paso 1',
              desc: 'Fraccionar',
              component: FraccionarForm,
            }
          : {},
      ];

      let cleanArray = stepMade.filter((el) => Object.keys(el).length !== 0);

      cleanArray = cleanArray.map((item, index) => {
        return {
          ...item,
          name: `Paso ${index + 1}`,
        };
      });

      return {
        ...state,
        stepsMaker: cleanArray,
        modalConvert: true,
        selectedPacienteTicket: comprobantes,
      };
    }
    case types.admin.balanceSunat.conversion.editSelectedPacienteTicket: {
      const { value, item } = action.payload;
      const comprobantes = {
        ...state.selectedPacienteTicket,
        detalles: state.selectedPacienteTicket.detalles.map((data) =>
          data.id === item.id ? { ...data, descripcion: value } : data
        ),
      };

      return {
        ...state,
        selectedPacienteTicket: comprobantes,
      };
    }
    case types.admin.balanceSunat.conversion.closeModalConvert: {
      return {
        ...state,
        modalConvert: false,
        selectedPacienteTicket: null,
      };
    }
    case types.admin.balanceSunat.conversion.buscarDocumento: {
      return {
        ...state,
        loadingBuscarDoc: true,
      };
    }
    case types.admin.balanceSunat.conversion.buscarDocumentoSuccess: {
      return {
        ...state,
        loadingBuscarDoc: false,
      };
    }
    case types.admin.balanceSunat.conversion.saveConvertTickets: {
      return {
        ...state,
        loadingSaveConvertTicket: true,
      };
    }
    case types.admin.balanceSunat.conversion.saveConvertTicketsSuccess: {
      return {
        ...state,
        loadingSaveConvertTicket: false,
        modalConvert: false,
        selectedPacienteTicket: null,
        selectedComprob: [],
      };
    }
    default:
      return { ...state };
  }
};
