import { types } from '../../../actions';

const initialState = {
  modalSolicitud: false,
  cargosSolicitud: [],
  horasExtraSolicitud: [],
  citaExtraSolicitud: [],
  loadingFechaSolicitud: false,
  pendingSolicitud: [],
  pendingSolicitudFilter: [],
  modalHoraExtraRevisar: false,
  selectedHoraExtraSolicitud: null,
  orderOptions: ['TODOS', 'ACEPTADO', 'PENDIENTE', 'RECHAZADO'],
  selectedOrderOption: 'TODOS',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.recursosHumanos.controlAsistencia.horasExtras
      .openModalSolicitud: {
      return {
        ...state,
        modalSolicitud: true,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .closeModalSolicitud: {
      return {
        ...state,
        modalSolicitud: false,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .getCargosSolicitudSuccess: {
      const { data } = action.payload;
      return {
        ...state,
        cargosSolicitud: data.opciones,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .selectFechaSolicitud: {
      return {
        ...state,
        loadingFechaSolicitud: true,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .selectFechaSolicitudSuccess: {
      const { data } = action.payload;

      return {
        ...state,
        loadingFechaSolicitud: false,
        horasExtraSolicitud: data.horaExtra,
        citaExtraSolicitud: data.citasEsp,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .clearFechaSolicitud: {
      return {
        ...state,
        loadingFechaSolicitud: false,
        horasExtraSolicitud: [],
        citaExtraSolicitud: [],
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .sendSolicitudExtra: {
      return {
        ...state,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .sendSolicitudExtraSuccess: {
      return {
        ...state,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .getPendinSolicitudExtra: {
      return {
        ...state,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .getPendinSolicitudExtraSuccess: {
      const { data } = action.payload;

      return {
        ...state,
        pendingSolicitud: data.pendiente,
        pendingSolicitudFilter: data.pendiente,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .openModalHoraExtraRevisar: {
      const { data } = action.payload;
      return {
        ...state,
        modalHoraExtraRevisar: true,
        selectedHoraExtraSolicitud: data,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .closeModalHoraExtraRevisar: {
      return {
        ...state,
        modalHoraExtraRevisar: false,
        selectedHoraExtraSolicitud: null,
      };
    }
    case types.recursosHumanos.controlAsistencia.horasExtras
      .selectedOrderOption: {
      const { data } = action.payload;

      let newArr = state.pendingSolicitud;
      if (data !== 'TODOS') {
        newArr = state.pendingSolicitud.filter((item) => item.estado === data);
      }

      return {
        ...state,
        selectedOrderOption: data,
        pendingSolicitudFilter: newArr,
      };
    }
    default:
      return state;
  }
};
