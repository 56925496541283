import { types } from '../../../actions';

export const openModalRecibos = (value) => ({
  type: types.asistente.paciente.recibos.openModalRecibos,
  payload: { value },
});

export const getReciboCitaSuccess = (value) => ({
  type: types.asistente.paciente.recibos.getReciboCitaSuccess,
  payload: { value },
});

export const closeModalRecibos = (value) => ({
  type: types.asistente.paciente.recibos.closeModalRecibos,
  payload: { value },
});

export const openModalPago = (value) => ({
  type: types.asistente.paciente.recibos.openModalPago,
  payload: { value },
});

export const cloaseModalPago = (value) => ({
  type: types.asistente.paciente.recibos.closeModalPago,
  payload: { value },
});

export const addCardPago = (value) => ({
  type: types.asistente.paciente.recibos.addCarPago,
  payload: { value },
});

export const deleteCardPago = (value) => ({
  type: types.asistente.paciente.recibos.deleteCarPago,
  payload: { value },
});

export const deleteAllCarPago = (value) => ({
  type: types.asistente.paciente.recibos.deleteAllCarPago,
  payload: { value },
});

export const sendRegistroPago = (value) => ({
  type: types.asistente.paciente.recibos.sendRegistroPago,
  payload: { value },
});

export const sendRegistroPagoSuccess = (value) => ({
  type: types.asistente.paciente.recibos.sendRegistroPagoSuccess,
  payload: { value },
});

export const getCitaIdPagoAdvance = (value) => ({
  type: types.asistente.paciente.recibos.getCitaIdPagoAdvance,
  payload: { value },
});

export const getCitaIdPagoAdvanceSuccess = (value) => ({
  type: types.asistente.paciente.recibos.getCitaIdPagoAdvanceSuccess,
  payload: { value },
});

export const editConfigPagos = (value) => ({
  type: types.asistente.paciente.recibos.editConfigPagos,
  payload: { value },
});

export const editConfigPagosRadio = (value) => ({
  type: types.asistente.paciente.recibos.editConfigPagosRadio,
  payload: { value },
});
