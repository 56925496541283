import { all, fork, takeEvery, put, call } from 'redux-saga/effects';
import { types } from '../actions';
import { OpenCaja, closeCaja, setIdCaja } from './actions';
import { apiCall } from '../../config/axiosApiCallWrapper';

function* getStateConfig({ payload }) {
  const { sucursalId } = payload;
  try {
    yield put({ type: 'LOADING', payload: 'CARGANDO CONTENIDO...' });
    const idSucursal = sucursalId;
    const responseCaja = yield call(
      apiCall,
      'POST',
      'asistente/caja/getEstadoCaja',
      { idSucursal }
    );
    if (responseCaja.success) {
      if (responseCaja.caja === 'ABIERTO') {
        yield put(OpenCaja());
        yield put(setIdCaja({ id_caja: responseCaja.idCaja }));
      } else {
        yield put(closeCaja);
        yield put(setIdCaja(''));
      }
    } else {
      yield put(closeCaja());
    }
    yield put({ type: 'LOADING_DISABLE', payload: '' });
  } catch (error) {
    console.log(error);
    yield put({ type: 'LOADING_DISABLE', payload: '' });
  }
}

export function* watchStateConfig() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(types.systemConf.GET_STATE_CONFIG, getStateConfig);
}

export default function* rootSaga() {
  yield all([fork(watchStateConfig)]);
}
