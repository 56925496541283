import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { types } from '../../actions';
import { getStatesSuccess, getRecentDocumentsSuccess } from './actions';
import { apiCall } from '../../../config/axiosApiCallWrapper';

function* getStates() {
  try {
    const idUser = yield select((state) => state.Auth.data.id);
    const response = yield call(
      apiCall,
      'POST',
      'mesaPartes/dashboard/getPanelData',
      {
        idUser,
      }
    );
    yield put(getStatesSuccess(response));
  } catch (err) {
    console.log(err);
  }
}

function* getRecentDocuments({ payload }) {
  try {
    console.log('getRecentDocuments');
    const estado = payload;
    const idUser = yield select((state) => state.Auth.data.id);
    const response = yield call(
      apiCall,
      'POST',
      'mesaPartes/dashboard/getRecentDocuments',
      {
        idUser,
        estado,
      }
    );
    yield put(getRecentDocumentsSuccess(response));
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetStates() {
  yield takeEvery(types.MesaPartes.dashboard.getStates, getStates);
}

export function* watchGetRecentDocuments() {
  yield takeEvery(
    types.MesaPartes.dashboard.getRecentDocuments,
    getRecentDocuments
  );
}

export default function* rootSaga() {
  yield all([fork(watchGetStates), fork(watchGetRecentDocuments)]);
}
