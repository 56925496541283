import { types } from '../../actions';

const initialState = {
  loadingStates: false,
  loadingDocuments: true,
  estadosDocument: null,
  recentDocuments: [],
  globalFilter: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MesaPartes.dashboard.getStates: {
      return {
        ...state,
        globalFilter: null,
        loadingStates: true,
      };
    }
    case types.MesaPartes.dashboard.getStatesSuccess: {
      const { estadosDocument, recentDocuments } = action.payload;
      return {
        ...state,
        loadingStates: false,
        loadingDocuments: false,
        estadosDocument,
        recentDocuments,
      };
    }
    case types.MesaPartes.dashboard.getRecentDocuments: {
      return {
        ...state,
        loadingDocuments: true,
      };
    }
    case types.MesaPartes.dashboard.getRecentDocumentsSuccess: {
      const { recentDocuments } = action.payload;
      return {
        ...state,
        loadingDocuments: false,
        recentDocuments,
      };
    }
    case types.MesaPartes.dashboard.setGolbalFilter: {
      return {
        ...state,
        globalFilter: {},
      };
    }
    default:
      return state;
  }
};
