import { types } from '../../actions';

export const getTramitesUserLoading = (data, page) => ({
  type: types.MesaPartes.Seguimiento.GET_TRAMITES_USER_LOADING,
  payload: { data, page },
});

export const openModalArchivos = (data) => ({
  type: types.MesaPartes.Seguimiento.OPEN_MODAL_ARCHIVOS,
  payload: data,
});

export const closeModalArchivos = () => ({
  type: types.MesaPartes.Seguimiento.CLOSE_MODAL_ARCHIVOS,
});

export const openModalSeguimiento = (data) => ({
  type: types.MesaPartes.Seguimiento.OPEN_MODAL_SEGUIMIENTO,
  payload: data,
});

export const closeModalSeguimiento = () => ({
  type: types.MesaPartes.Seguimiento.CLOSE_MODAL_SEGUIMIENTO,
});

export const setTramitesUser = (data) => ({
  type: types.MesaPartes.Seguimiento.SET_TRAMITES_USER,
  payload: data,
});

export const setArchivosSeguimiento = (data) => ({
  type: types.MesaPartes.Seguimiento.SET_ARCHIVOS_SEGUIMIENTO,
  payload: data,
});

export const setDataHistorial = (data) => ({
  type: types.MesaPartes.Seguimiento.SET_DATA_HISTORIAL,
  payload: data,
});
