import { types } from '../../actions';

export const getStates = (payload) => ({
  type: types.MesaPartes.dashboard.getStates,
  payload,
});

export const getStatesSuccess = (payload) => ({
  type: types.MesaPartes.dashboard.getStatesSuccess,
  payload,
});

export const getRecentDocuments = (payload) => ({
  type: types.MesaPartes.dashboard.getRecentDocuments,
  payload,
});

export const getRecentDocumentsSuccess = (payload) => ({
  type: types.MesaPartes.dashboard.getRecentDocumentsSuccess,
  payload,
});

export const setGlobalFilter = (payload) => ({
  type: types.MesaPartes.dashboard.setGolbalFilter,
  payload,
});
