import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Axios from 'axios';
import Url from '../../../config/Url';
import { GET_LOGO_CLINICA } from '../../actions';
import { getLogoClinicaSuccess, getLogoClinicaError } from './actions';

function* loadLogo(action) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(loadLogoAsync, action.payload);
    yield put(getLogoClinicaSuccess(response));
  } catch (error) {
    yield put(getLogoClinicaError(error));
  }
}

const loadLogoAsync = async (payload) => {
  try {
    const idSucursal = payload;
    const resp = await Axios.post(`${Url}getLogoClinica`, {
      idSucursal,
    });
    console.log(resp);
    return resp.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};
export function* watchLogo() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_LOGO_CLINICA, loadLogo);
}

export default function* rootSaga() {
  yield all([fork(watchLogo)]);
}
