export const Constants = () => {
  const CORONA_DEFINITIVA = 2;
  const CORONA_TEMPORAL = 3;
  const DIENTE_AUSENTE = 4;
  const FRACTURA = 5;
  const IMPLANTE = 6;
  const DIASTEMA = 8;
  const DIENTE_EXTRUIDO = 9;
  const DIENTE_EN_CLAVIJA = 10;
  const CURACION = 11;
  const PROTESIS_REMOVIBLE = 12;
  const MIGRACION = 13;
  const GIROVERSION = 14;
  const FUSION = 15;
  const REMANENTE_RADICULAR = 16;
  const MACRODONCIA = 17;
  const MICRODONCIA = 18;
  const IMPACTACION = 19;
  const DIENTE_INTRUIDO = 20;
  const DIENTE_ECTOPICO = 21;
  const DIENTE_DISCR0MICO = 22;
  const ORTONDICO_REMOVIBLE = 23;
  const DIENTE_EN_ERUPCION = 24;
  const TRANSPOSICION_LEFT = 25;
  const TRANSPOSICION_RIGHT = 26;
  const SUPER_NUMERARIO = 27;
  const PULPAR = 28;
  const PROTESIS_TOTAL = 29;
  const PERNO_MUNON = 30;
  // const EDENTULO_TOTAL = 31;
  const ORTODONTICO_FIJO = 32;
  const PROFILAXIS = 33;
  const PROTESIS_FIJA_LEFT = 34;
  const PROTESIS_FIJA = 35;
  const PROTESIS_FIJA_RIGHT = 36;
  const SUPERFICIE_DESGASTADA = 37;
  const SEMI_IMPACTACI0N = 38;

  // NUEVO TRATAMIENTO

  // CIRUGIA 3RA MOLAR INFERIOR
  const CIRUGIA_DIAGNOSTICO_IMPACTADO = 39;
  const CIRUGIA_DIAGNOSTICO_SEMI_IMPACTADO = 40;
  const CIRUGIA_INICIO = 41;
  const CIRUGIA_TERMINO = 42;
  const CIRUGIA_RETIRO_PTS_CONTROL_POS = 43;

  // PROFILAXIS COMPLETA
  const PROFILAXIS_1RA_ULTRASONIDO = 44;
  const PROFILAXIS_2RA_CURETAJE = 45;
  const PROFILAXIS_3RA_PULIDO = 46;

  // perno de metal
  const PERNO_METAL_TALLADO_PERNO = 47;
  const PERNO_METAL_CEMENTACION = 48;

  // perno fibra de vidrio
  const PERNO_FIBRA_TALLADO_PERNO = 49;
  const PERNO_FIBRA_CEMENTACION = 50;

  // corona de porcelana
  const CORONA_TALLADO = 51;
  const CORONA_CEMENTACION = 52;

  // puente de porcelana
  const PUENTE_TALLADO = 53;
  const PUENTE_PROVISIONAL = 54;
  const PUENTE_CEMENTACION = 56;

  // PROTESIS REMOVIBLE
  const PROTESIS_REMOVIBLE_IMPRESION_CUBETA = 57;
  const PROTESIS_REMOVIBLE_IMPRESION_DEFINITIVA = 58;
  const PROTESIS_REMOVIBLE_PRUEBA_RODETE = 59;
  const PROTESIS_REMOVIBLE_PRUEBA_ENFILADO = 60;
  const PROTESIS_REMOVIBLE_ENTREGA_TRABAJO = 61;

  // PROTESIS TOTAL (OBSERVACION)
  const PROTESIS_TOTAL_IMPRESION_CUBETA = 62;
  const PROTESIS_TOTAL_IMPRESION_DEFINITIVA = 63;
  const PROTESIS_TOTAL_PRUEBA_RODETE = 663;
  const PROTESIS_TOTAL_PRUEBA_ENFILADO = 64;
  const PROTESIS_TOTAL_ENTREGA_TRABAJO = 664;

  // ENDODONCIA
  const ENDODONCIA = 65;
  const ENDODONCIA_INICIO = 998;
  const ENDODONCIA_INSTRUMENTACION = 66;
  const ENDODONCIA_CONDUCTOMETRIA = 67;
  const ENDODONCIA_FIN = 999;
  const ENDODONCIA_MAL = 68;

  // EXODONCIA
  const EXODONCIA = 69;
  const EXODONCIA_TERMINO = 70;

  // PULPECTOMIA
  const PULPECTOMIA_APERTURA_CAMERAL = 71;
  const PULPECTOMIA_MEDICION = 72;
  const PULPECTOMIA_LAVADO = 73;
  const PULPECTOMIA_OBTURACION = 74;

  // PULPOTOMIA
  const PULPOTOMIA_APERTURA_CAMERAL = 75;
  const PULPOTOMIA_MEDICION = 76;
  const PULPOTOMIA_LAVADO = 77;
  const PULPOTOMIA_OBTURACION = 78;

  // CARIES
  const CARIES_COMPLETADO = 79;
  const CARIES = 80;

  // FIN DE NUEVO TRATAMIENTO

  const PULPAR_INICIO = 228;
  const PROTESIS_TOTAL_MAL = 229;
  const PROTESIS_REMOVIBLE_MAL = 112;
  const DESGASTE_OCLUSAL_INCISAL = 115;
  const IONOMERO_VIDRIO = 123;
  const AMALGAMA = 152;
  const ORTODONTICO_FIJO_MAL = 332;
  const PROFILAXIS_MAL = 333;
  const PROTESIS_FIJA_MAL = 335;
  const DIENTE_EXTRACCION = 441;
  const OBSERVACION = -999;
  const COMPLETADO = -998;
  const RESINA = 1121;
  const INCRUSTACION_METALICA = 1231;
  const INCRUSTACION_ESTETICA = 1234;

  const all = {
    CARIES,
    CARIES_COMPLETADO,
    CORONA_DEFINITIVA,
    CORONA_TEMPORAL,
    DIENTE_AUSENTE,
    FRACTURA,
    DIASTEMA,
    DIENTE_EXTRUIDO,
    DIENTE_EN_CLAVIJA,
    CURACION,
    PROTESIS_REMOVIBLE,
    PROTESIS_REMOVIBLE_MAL,
    MIGRACION,
    GIROVERSION,
    FUSION,
    REMANENTE_RADICULAR,
    DIENTE_INTRUIDO,
    ORTONDICO_REMOVIBLE,
    DIENTE_EN_ERUPCION,
    TRANSPOSICION_LEFT,
    TRANSPOSICION_RIGHT,
    SUPER_NUMERARIO,
    PULPAR,
    PULPAR_INICIO,
    PROTESIS_TOTAL,
    PROTESIS_TOTAL_MAL,
    PERNO_MUNON,
    // EDENTULO_TOTAL,
    ORTODONTICO_FIJO,
    ORTODONTICO_FIJO_MAL,
    PROFILAXIS,
    PROFILAXIS_MAL,
    PROTESIS_FIJA_LEFT,
    PROTESIS_FIJA,
    PROTESIS_FIJA_MAL,
    PROTESIS_FIJA_RIGHT,
    IMPLANTE,
    MACRODONCIA,
    MICRODONCIA,
    IMPACTACION,
    DIENTE_ECTOPICO,
    DIENTE_DISCR0MICO,
    SUPERFICIE_DESGASTADA,
    SEMI_IMPACTACI0N,
    DIENTE_EXTRACCION,
    EXODONCIA,
    EXODONCIA_TERMINO,
    ENDODONCIA,
    ENDODONCIA_INSTRUMENTACION,
    ENDODONCIA_CONDUCTOMETRIA,
    ENDODONCIA_INICIO,
    ENDODONCIA_FIN,
    ENDODONCIA_MAL,
    OBSERVACION,
    COMPLETADO,
    DESGASTE_OCLUSAL_INCISAL,
    AMALGAMA,
    RESINA,
    IONOMERO_VIDRIO,
    INCRUSTACION_METALICA,
    INCRUSTACION_ESTETICA,
    CIRUGIA_INICIO,
    CIRUGIA_TERMINO,
    CIRUGIA_RETIRO_PTS_CONTROL_POS,
    PROFILAXIS_1RA_ULTRASONIDO,
    PROFILAXIS_2RA_CURETAJE,
    PROFILAXIS_3RA_PULIDO,
    PERNO_METAL_TALLADO_PERNO,
    PERNO_METAL_CEMENTACION,
    PERNO_FIBRA_TALLADO_PERNO,
    PERNO_FIBRA_CEMENTACION,
    CORONA_TALLADO,
    CORONA_CEMENTACION,
    PUENTE_TALLADO,
    PUENTE_PROVISIONAL,
    PUENTE_CEMENTACION,
    PROTESIS_REMOVIBLE_IMPRESION_CUBETA,
    PROTESIS_REMOVIBLE_IMPRESION_DEFINITIVA,
    PROTESIS_REMOVIBLE_PRUEBA_RODETE,
    PROTESIS_REMOVIBLE_PRUEBA_ENFILADO,
    PROTESIS_REMOVIBLE_ENTREGA_TRABAJO,
    PROTESIS_TOTAL_IMPRESION_CUBETA,
    PROTESIS_TOTAL_IMPRESION_DEFINITIVA,
    PROTESIS_TOTAL_PRUEBA_RODETE,
    PROTESIS_TOTAL_PRUEBA_ENFILADO,
    PROTESIS_TOTAL_ENTREGA_TRABAJO,
  };

  return all;
};

export const dataDamage = [
  [
    {
      tip: 'CORONA DEFINITIVA',
      image: 'corona_definitiva',
      name: Constants().CORONA_DEFINITIVA,
      selected: {
        tip: 'CORONA DEFINITIVA',
        image: 'corona_definitiva',
        name: Constants().CORONA_DEFINITIVA,
      },
    },
    {
      tip: 'CORONA TEMPORAL',
      image: 'corona_temporal',
      name: Constants().CORONA_TEMPORAL,
    },
    {
      tip: 'TALLADO CORONA',
      image: 'corona_tallado',
      name: Constants().CORONA_TALLADO,
    },
    {
      tip: 'CEMENTACION CORONA',
      image: 'corona_cementacion',
      name: Constants().CORONA_CEMENTACION,
    },
  ],
  [
    {
      tip: 'DIENTE AUSENTE',
      image: 'diente_ausente',
      name: Constants().DIENTE_AUSENTE,
      selected: {
        tip: 'DIENTE AUSENTE',
        image: 'diente_ausente',
        name: Constants().DIENTE_AUSENTE,
      },
    },
    {
      tip: 'DIENTE EXTRACCION',
      image: 'diente_extraccion',
      name: Constants().DIENTE_EXTRACCION,
    },
    {
      tip: 'EXODONCIA',
      image: 'exodoncia',
      name: Constants().EXODONCIA,
    },
    {
      tip: 'TERMINO EXODONCIA',
      image: 'exodoncia_termino',
      name: Constants().EXODONCIA_TERMINO,
    },
  ],
  {
    tip: 'FRACTURA',
    image: 'fractura',
    name: Constants().FRACTURA,
  },
  [
    {
      tip: 'DIENTE EXTRUIDO',
      image: 'diente_extruido',
      name: Constants().DIENTE_EXTRUIDO,
      selected: {
        tip: 'DIENTE EXTRUIDO',
        image: 'diente_extruido',
        name: Constants().DIENTE_EXTRUIDO,
      },
    },
    {
      tip: 'MIGRACION',
      image: 'migracion',
      name: Constants().MIGRACION,
    },
    {
      tip: 'GIROVERSION',
      image: 'giroversion',
      name: Constants().GIROVERSION,
    },
    {
      tip: 'DIENTE INTRUIDO',
      image: 'diente_intruido',
      name: Constants().DIENTE_INTRUIDO,
    },
  ],
  {
    tip: 'DIENTE EN CLAVIJA',
    image: 'diente_clavija',
    name: Constants().DIENTE_EN_CLAVIJA,
  },
  [
    {
      tip: 'PROTESIS REMOVIBLE',
      image: 'protesis_removible',
      name: Constants().PROTESIS_REMOVIBLE,
      selected: {
        tip: 'PROTESIS REMOVIBLE',
        image: 'protesis_removible',
        name: Constants().PROTESIS_REMOVIBLE,
      },
    },
    {
      tip: 'IMPRESION CUBETA INDIVIDUAL',
      image: 'protesis_removible_impresion_cubeta',
      name: Constants().PROTESIS_REMOVIBLE_IMPRESION_CUBETA,
    },
    {
      tip: 'IMPRESION DEFINITIVA CUBETA',
      image: 'protesis_removible_impresion_definitiva',
      name: Constants().PROTESIS_REMOVIBLE_IMPRESION_DEFINITIVA,
    },
    {
      tip: 'PRUEBA RODETE',
      image: 'protesis_removible_prueba_rodete',
      name: Constants().PROTESIS_REMOVIBLE_PRUEBA_RODETE,
    },
    {
      tip: 'PRUEBA ENFILADO',
      image: 'protesis_removible_prueba_enfilado',
      name: Constants().PROTESIS_REMOVIBLE_PRUEBA_ENFILADO,
    },
    {
      tip: 'ENTREGA TRABAJO',
      image: 'protesis_removible_trabajo_acrilizado',
      name: Constants().PROTESIS_REMOVIBLE_ENTREGA_TRABAJO,
    },
    {
      tip: 'PROTESIS REMOVIBLE MAL',
      image: 'protesis_removible_mal',
      name: Constants().PROTESIS_REMOVIBLE_MAL,
    },
  ],
  {
    tip: 'FUSION',
    image: 'fusion',
    name: Constants().FUSION,
  },
  {
    tip: 'DIENTE EN ERUPCION',
    image: 'diente_erupcion',
    name: Constants().DIENTE_EN_ERUPCION,
  },
  [
    {
      tip: 'PERNO FIBRA',
      image: 'perno_fibra',
      name: Constants().PULPAR,
      selected: {
        tip: 'PERNO FIBRA',
        image: 'perno_fibra',
        name: Constants().PULPAR,
      },
    },
    {
      tip: 'PERNO FIBRA TALLADO PERNO',
      image: 'perno_fibra_vidrio',
      name: Constants().PERNO_FIBRA_TALLADO_PERNO,
    },
    {
      tip: 'PERNO FIBRA CEMENTACION',
      image: 'perno_fibra_cementacion',
      name: Constants().PERNO_FIBRA_CEMENTACION,
    },
  ],
  [
    {
      tip: 'PROTESIS TOTAL',
      image: 'protesis_total',
      name: Constants().PROTESIS_TOTAL,
      selected: {
        tip: 'PROTESIS TOTAL',
        image: 'protesis_total',
        name: Constants().PROTESIS_TOTAL,
      },
    },
    {
      tip: 'IMPRESION PARA CUBETA',
      image: 'protesis_total_impresion_cubeta',
      name: Constants().PROTESIS_TOTAL_IMPRESION_CUBETA,
    },
    {
      tip: 'IMPRESION DEFINITIVA',
      image: 'protesis_total_impresion_definitiva',
      name: Constants().PROTESIS_TOTAL_IMPRESION_DEFINITIVA,
    },
    {
      tip: 'PRUEBA RODETE',
      image: 'protesis_total_prueba_rodete',
      name: Constants().PROTESIS_TOTAL_PRUEBA_RODETE,
    },
    {
      tip: 'PRUEBA ENFILADO',
      image: 'protesis_total_prueba_enfilado',
      name: Constants().PROTESIS_TOTAL_PRUEBA_ENFILADO,
    },
    {
      tip: 'ENTREGA TRABAJO',
      image: 'protesis_total_entrega_trabajo',
      name: Constants().PROTESIS_TOTAL_ENTREGA_TRABAJO,
    },
    {
      tip: 'PROTESIS TOTAL MAL',
      image: 'protesis_total_mal',
      name: Constants().PROTESIS_TOTAL_MAL,
    },
  ],
  [
    {
      tip: 'PERNO METAL',
      image: 'perno_munon',
      name: Constants().PERNO_MUNON,
      selected: {
        tip: 'PERNO MUNON',
        image: 'perno_munon',
        name: Constants().PERNO_MUNON,
      },
    },
    {
      tip: 'PERNO METAL TALLADO DE PERNO',
      image: 'perno_tallado',
      name: Constants().PERNO_METAL_TALLADO_PERNO,
    },
    {
      tip: 'PERNO METAL CEMENTACION DE PERNO',
      image: 'perno_cementacion',
      name: Constants().PERNO_METAL_CEMENTACION,
    },
  ],
  [
    {
      tip: 'ORTODONTICO FIJO',
      image: 'ortodontico_fijo',
      name: Constants().ORTODONTICO_FIJO,
      selected: {
        tip: 'ORTODONTICO FIJO',
        image: 'ortodontico_fijo',
        name: Constants().ORTODONTICO_FIJO,
      },
    },
    {
      tip: 'ORTODONTICO FIJO MAL',
      image: 'ortodontico_fijo_mal',
      name: Constants().ORTODONTICO_FIJO_MAL,
    },
  ],
  [
    {
      tip: 'PROFILAXIS GENGIVITIS',
      image: 'profilaxis',
      name: Constants().PROFILAXIS,
      selected: {
        tip: 'PROFILAXIS GENGIVITIS',
        image: 'profilaxis',
        name: Constants().PROFILAXIS,
      },
    },
    {
      tip: '1RA PROF. ULTRASONIDO',
      image: '1ra_prof_ultrasonido',
      name: Constants().PROFILAXIS_1RA_ULTRASONIDO,
    },
    {
      tip: '2DA PROF. ULTRASONIDO/CURETAJE',
      image: '2da_prof_ultra_cureta',
      name: Constants().PROFILAXIS_2RA_CURETAJE,
    },
    {
      tip: '3RA PROF. PULIDO',
      image: '3ra_prof_pulido',
      name: Constants().PROFILAXIS_3RA_PULIDO,
    },
    {
      tip: 'PROFILAXIS PERIODONTITIS',
      image: 'profilaxis_mal',
      name: Constants().PROFILAXIS_MAL,
    },
  ],
  [
    {
      tip: 'PROTESIS FIJA',
      image: 'protesis_fija',
      name: Constants().PROTESIS_FIJA,
      selected: {
        tip: 'PROTESIS FIJA',
        image: 'protesis_fija',
        name: Constants().PROTESIS_FIJA,
      },
    },
    {
      tip: 'TALLADO PUENTE',
      image: 'protesis_fija_tallado',
      name: Constants().PUENTE_TALLADO,
    },
    {
      tip: 'PROVISIONAL',
      image: 'protesis_fija_provisional',
      name: Constants().PUENTE_PROVISIONAL,
    },
    {
      tip: 'CEMENTACION DEFINITIVA',
      image: 'protesis_fija_cementacion',
      name: Constants().PUENTE_CEMENTACION,
    },
    {
      tip: 'PROTESIS FIJA MAL',
      image: 'protesis_fija_mal',
      name: Constants().PROTESIS_FIJA_MAL,
    },
  ],
  [
    {
      tip: 'ENDODONCIA',
      image: 'endodoncia',
      name: Constants().ENDODONCIA,
      selected: {
        tip: 'ENDODONCIA',
        image: 'endodoncia',
        name: Constants().ENDODONCIA,
      },
    },
    {
      tip: 'ENDODONCIA INICIO',
      image: 'endodoncia_inicio',
      name: Constants().ENDODONCIA_INICIO,
    },
    {
      tip: 'INSTRUMENTACION ENDODONCIA',
      image: 'endodoncia_instrumentacion',
      name: Constants().ENDODONCIA_INSTRUMENTACION,
    },
    {
      tip: 'CONDUCTOMETRIA',
      image: 'endodoncia_conductometria',
      name: Constants().ENDODONCIA_CONDUCTOMETRIA,
    },
    {
      tip: 'ENDODONCIA FIN',
      image: 'endodoncia_fin',
      name: Constants().ENDODONCIA_FIN,
    },
    {
      tip: 'ENDODONCIA MAL',
      image: 'endodoncia_mal',
      name: Constants().ENDODONCIA_MAL,
    },
  ],
  [
    {
      tip: 'OBSERVACION',
      image: 'observacion',
      name: Constants().OBSERVACION,
      selected: {
        tip: 'OBSERVACION',
        image: 'observacion',
        name: Constants().OBSERVACION,
      },
    },
    {
      tip: 'COMPLETADO',
      image: 'completado',
      name: Constants().COMPLETADO,
    },
  ],
  [
    {
      tip: 'DESGASTE_OCLUSAL_INCISAL',
      image: 'desgaste_oclusal_incisal',
      name: Constants().DESGASTE_OCLUSAL_INCISAL,
      selected: {
        tip: 'DESGASTE_OCLUSAL_INCISAL',
        image: 'desgaste_oclusal_incisal',
        name: Constants().DESGASTE_OCLUSAL_INCISAL,
      },
    },
    {
      tip: 'DIENTE_DISCR0MICO',
      image: 'diente_discromico',
      name: Constants().DIENTE_DISCR0MICO,
    },
    {
      tip: 'DIENTE_ECTOPICO',
      image: 'diente_ectopico',
      name: Constants().DIENTE_ECTOPICO,
    },
  ],
  [
    {
      tip: 'IMPACTACION',
      image: 'impactacion',
      name: Constants().IMPACTACION,
      selected: {
        tip: 'IMPACTACION',
        image: 'impactacion',
        name: Constants().IMPACTACION,
      },
    },
    {
      tip: 'IMPLANTE',
      image: 'implante',
      name: Constants().IMPLANTE,
    },
    {
      tip: 'MACRODONCIA',
      image: 'macrodoncia',
      name: Constants().MACRODONCIA,
    },
    {
      tip: 'MICRODONCIA',
      image: 'microdoncia',
      name: Constants().MICRODONCIA,
    },
  ],
  [
    {
      tip: 'AMALGAMA',
      image: 'amalgama',
      name: Constants().AMALGAMA,
      selected: {
        tip: 'AMALGAMA',
        image: 'amalgama',
        name: Constants().AMALGAMA,
      },
    },
    {
      tip: 'RESINA',
      image: 'resina',
      name: Constants().RESINA,
    },
    {
      tip: 'IONOMERO_VIDRIO',
      image: 'ionomero_vidrio',
      name: Constants().IONOMERO_VIDRIO,
    },
    {
      tip: 'INCRUSTACION_METALICA',
      image: 'incrustacion_metalica',
      name: Constants().INCRUSTACION_METALICA,
    },
    {
      tip: 'INCRUSTACION_ESTETICA',
      image: 'incrustacion_estetica',
      name: Constants().INCRUSTACION_ESTETICA,
    },
  ],
  [
    {
      tip: 'INICIO DE CIRUGIA',
      image: 'inicio_cirugia',
      name: Constants().CIRUGIA_INICIO,
      selected: {
        tip: 'INICIO DE CIRUGIA',
        image: 'inicio_cirugia',
        name: Constants().CIRUGIA_INICIO,
      },
    },
    {
      tip: 'TERMINO DE CIRUGIA',
      image: 'termino_cirugia',
      name: Constants().CIRUGIA_TERMINO,
    },
    {
      tip: 'RETIRO DE PUNTOS Y CONTROL POS CIRUGIA ',
      image: 'retiro_puntos_cirugia',
      name: Constants().CIRUGIA_RETIRO_PTS_CONTROL_POS,
    },
  ],
];

export const damageSet = [
  {
    tip: 'CARIES',
    image: 'caries',
    name: Constants().CARIES,
  },
  {
    tip: 'CARIES COMPLETADO',
    image: 'caries_completado',
    name: Constants().CARIES_COMPLETADO,
  },
  {
    tip: 'CORONA DEFINITIVA',
    image: 'corona_definitiva',
    name: Constants().CORONA_DEFINITIVA,
  },
  {
    tip: 'CORONA TEMPORAL',
    image: 'corona_temporal',
    name: Constants().CORONA_TEMPORAL,
  },
  {
    tip: 'DIENTE EXTRACCION',
    image: 'diente_extraccion',
    name: Constants().DIENTE_EXTRACCION,
  },
  {
    tip: 'EXODONCIA',
    image: 'exodoncia',
    name: Constants().EXODONCIA,
  },
  {
    tip: 'TERMINO EXODONCIA',
    image: 'exodoncia_termino',
    name: Constants().EXODONCIA_TERMINO,
  },
  {
    tip: 'DIENTE AUSENTE',
    image: 'diente_ausente',
    name: Constants().DIENTE_AUSENTE,
  },
  {
    tip: 'FRACTURA',
    image: 'fractura',
    name: Constants().FRACTURA,
  },
  {
    tip: 'DIENTE EXTRUIDO',
    image: 'diente_extruido',
    name: Constants().DIENTE_EXTRUIDO,
  },
  {
    tip: 'DIENTE EN CLAVIJA',
    image: 'diente_clavija',
    name: Constants().DIENTE_EN_CLAVIJA,
  },
  {
    tip: 'PROTESIS REMOVIBLE',
    image: 'protesis_removible',
    name: Constants().PROTESIS_REMOVIBLE,
  },
  {
    tip: 'PROTESIS REMOVIBLE EN MAL ESTADO',
    image: 'protesis_removible_mal',
    name: Constants().PROTESIS_REMOVIBLE_MAL,
  },
  {
    tip: 'MIGRACION',
    image: 'migracion',
    name: Constants().MIGRACION,
  },
  {
    tip: 'GIROVERSION',
    image: 'giroversion',
    name: Constants().GIROVERSION,
  },
  {
    tip: 'FUSION',
    image: 'fusion',
    name: Constants().FUSION,
  },
  {
    tip: 'DIENTE INTRUIDO',
    image: 'diente_intruido',
    name: Constants().DIENTE_INTRUIDO,
  },
  {
    tip: 'ORTONDICO REMOVIBLE',
    image: 'ortondico_removible',
    name: Constants().ORTONDICO_REMOVIBLE,
  },
  {
    tip: 'DIENTE EN ERUPCION',
    image: 'diente_erupcion',
    name: Constants().DIENTE_EN_ERUPCION,
  },
  {
    tip: 'PERNO FIBRA',
    image: 'perno_fibra',
    name: Constants().PULPAR,
  },
  {
    tip: 'PERNO FIBRA TALLADO PERNO',
    image: 'perno_fibra_vidrio',
    name: Constants().PERNO_FIBRA_TALLADO_PERNO,
  },
  {
    tip: 'PERNO FIBRA CEMENTACION',
    image: 'perno_fibra_cementacion',
    name: Constants().PERNO_FIBRA_CEMENTACION,
  },
  {
    tip: 'PULPAR INICIO',
    image: 'pulpar_inicio',
    name: Constants().PULPAR_INICIO,
  },
  {
    tip: 'PROTESIS TOTAL',
    image: 'protesis_total',
    name: Constants().PROTESIS_TOTAL,
  },
  {
    tip: 'PROTESIS TOTAL EN MAL ESTADO',
    image: 'protesis_total_mal',
    name: Constants().PROTESIS_TOTAL_MAL,
  },
  {
    tip: 'PERNO MUNON',
    image: 'perno_munon',
    name: Constants().PERNO_MUNON,
  },
  {
    tip: 'PERNO METAL TALLADO DE PERNO',
    image: 'perno_tallado',
    name: Constants().PERNO_METAL_TALLADO_PERNO,
  },
  {
    tip: 'PERNO METAL CEMENTACION DE PERNO',
    image: 'perno_cementacion',
    name: Constants().PERNO_METAL_CEMENTACION,
  },
  {
    tip: 'ORTODONTICO FIJO',
    image: 'ortodontico_fijo',
    name: Constants().ORTODONTICO_FIJO,
  },
  {
    tip: 'ORTODONTICO FIJO EN MAL ESTADO',
    image: 'ortodontico_fijo_mal',
    name: Constants().ORTODONTICO_FIJO_MAL,
  },
  {
    tip: 'PROFILAXIS GENGIVITIS',
    image: 'profilaxis',
    name: Constants().PROFILAXIS,
  },
  {
    tip: '1RA PROF. ULTRASONIDO',
    image: '1ra_prof_ultrasonido',
    name: Constants().PROFILAXIS_1RA_ULTRASONIDO,
  },
  {
    tip: '2DA PROF. ULTRASONIDO/CURETAJE',
    image: '2da_prof_ultra_cureta',
    name: Constants().PROFILAXIS_2RA_CURETAJE,
  },
  {
    tip: '3RA PROF. PULIDO',
    image: '3ra_prof_pulido',
    name: Constants().PROFILAXIS_3RA_PULIDO,
  },
  {
    tip: 'PROFILAXIS PERIODONTITIS',
    image: 'profilaxis_mal',
    name: Constants().PROFILAXIS_MAL,
  },
  {
    tip: 'PROTESIS FIJA',
    image: 'protesis_fija',
    name: Constants().PROTESIS_FIJA,
  },
  {
    tip: 'PROTESIS FIJA EN MAL ESTADO',
    image: 'protesis_fija_mal',
    name: Constants().PROTESIS_FIJA_MAL,
  },
  {
    tip: 'ENDODONCIA INICIO',
    image: 'endodoncia_inicio',
    name: Constants().ENDODONCIA_INICIO,
  },
  {
    tip: 'ENDODONCIA',
    image: 'endodoncia',
    name: Constants().ENDODONCIA,
  },
  {
    tip: 'INSTRUMENTACION ENDODONCIA',
    image: 'endodoncia_instrumentacion',
    name: Constants().ENDODONCIA_INSTRUMENTACION,
  },
  {
    tip: 'CONDUCTOMETRIA',
    image: 'endodoncia_conductometria',
    name: Constants().ENDODONCIA_CONDUCTOMETRIA,
  },
  {
    tip: 'ENDODONCIA FIN',
    image: 'endodoncia_fin',
    name: Constants().ENDODONCIA_FIN,
  },
  {
    tip: 'ENDODONCIA MAL',
    image: 'endodoncia_mal',
    name: Constants().ENDODONCIA_MAL,
  },
  {
    tip: 'OBSERVACION',
    image: 'observacion',
    name: Constants().OBSERVACION,
  },
  {
    tip: 'COMPLETADO',
    image: 'completado',
    name: Constants().COMPLETADO,
  },
  {
    tip: 'DESGASTE_OCLUSAL_INCISAL',
    image: 'desgaste_oclusal_incisal',
    name: Constants().DESGASTE_OCLUSAL_INCISAL,
  },
  {
    tip: 'DIENTE_DISCR0MICO',
    image: 'diente_discromico',
    name: Constants().DIENTE_DISCR0MICO,
  },
  {
    tip: 'DIENTE_ECTOPICO',
    image: 'diente_ectopico',
    name: Constants().DIENTE_ECTOPICO,
  },
  {
    tip: 'IMPACTACION',
    image: 'impactacion',
    name: Constants().IMPACTACION,
  },
  {
    tip: 'IMPLANTE',
    image: 'implante',
    name: Constants().IMPLANTE,
  },
  {
    tip: 'MACRODONCIA',
    image: 'macrodoncia',
    name: Constants().MACRODONCIA,
  },
  {
    tip: 'MICRODONCIA',
    image: 'microdoncia',
    name: Constants().MICRODONCIA,
  },
  {
    tip: 'AMALGAMA',
    image: 'amalgama',
    name: Constants().AMALGAMA,
  },
  {
    tip: 'RESINA',
    image: 'resina',
    name: Constants().RESINA,
  },
  {
    tip: 'IONOMERO_VIDRIO',
    image: 'ionomero_vidrio',
    name: Constants().IONOMERO_VIDRIO,
  },
  {
    tip: 'INCRUSTACION_METALICA',
    image: 'incrustacion_metalica',
    name: Constants().INCRUSTACION_METALICA,
  },
  {
    tip: 'INCRUSTACION_ESTETICA',
    image: 'incrustacion_estetica',
    name: Constants().INCRUSTACION_ESTETICA,
  },
  {
    tip: 'INICIO DE CIRUGIA',
    image: 'inicio_cirugia',
    name: Constants().CIRUGIA_INICIO,
  },
  {
    tip: 'TERMINO DE CIRUGIA',
    image: 'termino_cirugia',
    name: Constants().CIRUGIA_TERMINO,
  },
  {
    tip: 'RETIRO DE PUNTOS Y CONTROL POS CIRUGIA ',
    image: 'retiro_puntos_cirugia',
    name: Constants().CIRUGIA_RETIRO_PTS_CONTROL_POS,
  },
  {
    tip: 'TALLADO CORONA',
    image: 'corona_tallado',
    name: Constants().CORONA_TALLADO,
  },
  {
    tip: 'CEMENTACION CORONA',
    image: 'corona_cementacion',
    name: Constants().CORONA_CEMENTACION,
  },
  {
    tip: 'TALLADO PUENTE',
    image: 'protesis_fija_tallado',
    name: Constants().PUENTE_TALLADO,
  },
  {
    tip: 'PROVISIONAL',
    image: 'protesis_fija_provisional',
    name: Constants().PUENTE_PROVISIONAL,
  },
  {
    tip: 'CEMENTACION DEFINITIVA',
    image: 'protesis_fija_cementacion',
    name: Constants().PUENTE_CEMENTACION,
  },
  {
    tip: 'IMPRESION CUBETA INDIVIDUAL',
    image: 'protesis_removible_impresion_cubeta',
    name: Constants().PROTESIS_REMOVIBLE_IMPRESION_CUBETA,
  },
  {
    tip: 'IMPRESION DEFINITIVA CUBETA',
    image: 'protesis_removible_impresion_definitiva',
    name: Constants().PROTESIS_REMOVIBLE_IMPRESION_DEFINITIVA,
  },
  {
    tip: 'PRUEBA RODETE',
    image: 'protesis_removible_prueba_rodete',
    name: Constants().PROTESIS_REMOVIBLE_PRUEBA_RODETE,
  },
  {
    tip: 'PRUEBA ENFILADO',
    image: 'protesis_removible_prueba_enfilado',
    name: Constants().PROTESIS_REMOVIBLE_PRUEBA_ENFILADO,
  },
  {
    tip: 'ENTREGA TRABAJO',
    image: 'protesis_removible_trabajo_acrilizado',
    name: Constants().PROTESIS_REMOVIBLE_ENTREGA_TRABAJO,
  },
  {
    tip: 'IMPRESION PARA CUBETA',
    image: 'protesis_total_impresion_cubeta',
    name: Constants().PROTESIS_TOTAL_IMPRESION_CUBETA,
  },
  {
    tip: 'IMPRESION DEFINITIVA',
    image: 'protesis_total_impresion_definitiva',
    name: Constants().PROTESIS_TOTAL_IMPRESION_DEFINITIVA,
  },
  {
    tip: 'PRUEBA RODETE',
    image: 'protesis_total_prueba_rodete',
    name: Constants().PROTESIS_TOTAL_PRUEBA_RODETE,
  },
  {
    tip: 'PRUEBA ENFILADO',
    image: 'protesis_total_prueba_enfilado',
    name: Constants().PROTESIS_TOTAL_PRUEBA_ENFILADO,
  },
  {
    tip: 'ENTREGA TRABAJO',
    image: 'protesis_total_entrega_trabajo',
    name: Constants().PROTESIS_TOTAL_ENTREGA_TRABAJO,
  },
];
