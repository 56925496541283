import { types } from '../../actions';

const initialState = {
  modalArchivosState: false,
  modalSeguimientoState: false,
  loadingTramites: false,
  tramitesUser: [],
  tramitesUserData: [],
  loadingArchivos: false,
  loadingAnexos: false,
  archivosSeguimiento: [],
  anexosSeguimiento: [],
  loadingHistorial: false,
  datosHistorial: [],
  fechaInicioHistorial: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MesaPartes.Seguimiento.OPEN_MODAL_ARCHIVOS:
      return {
        ...state,
        modalArchivosState: true,
        loadingArchivos: true,
        loadingAnexos: true,
      };
    case types.MesaPartes.Seguimiento.CLOSE_MODAL_ARCHIVOS:
      return {
        ...state,
        modalArchivosState: false,
      };
    case types.MesaPartes.Seguimiento.OPEN_MODAL_SEGUIMIENTO:
      return {
        ...state,
        modalSeguimientoState: true,
        loadingHistorial: true,
      };
    case types.MesaPartes.Seguimiento.CLOSE_MODAL_SEGUIMIENTO:
      return {
        ...state,
        modalSeguimientoState: false,
      };
    case types.MesaPartes.Seguimiento.GET_TRAMITES_USER_LOADING:
      return {
        ...state,
        loadingTramites: true,
      };
    case types.MesaPartes.Seguimiento.SET_TRAMITES_USER:
      return {
        ...state,
        loadingTramites: false,
        tramitesUser: action.payload,
        tramitesUserData: action.payload.data,
      };
    case types.MesaPartes.Seguimiento.SET_ARCHIVOS_SEGUIMIENTO:
      return {
        ...state,
        loadingArchivos: false,
        loadingAnexos: false,
        archivosSeguimiento: action.payload.archivos,
        anexosSeguimiento: action.payload.anexos,
      };
    case types.MesaPartes.Seguimiento.SET_DATA_HISTORIAL:
      return {
        ...state,
        loadingHistorial: false,
        datosHistorial: action.payload.historial,
        fechaInicioHistorial: action.payload.fechaInicio,
      };
    default:
      return state;
  }
};
