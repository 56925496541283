import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const ModalTrabajo = ({
  modal,
  toggle,
  handleChangeSelectedDmg,
  setSelectedContext,
  setCancelModal,
}) => {
  const [values, setValues] = useState({
    sesiones: '',
    fecha_inicio: '',
    fecha_fin: '',
    observaciones: '',
  });

  const checkModal = () => {
    if (
      values.sesiones === '0' ||
      values.fecha_inicio === '' ||
      values.fecha_fin === ''
    ) {
      MySwal.fire({
        title: 'Error :(',
        text: 'Es obligatorio llenar los campos de sesión y fecha',
        icon: 'error',
      });
    } else {
      setSelectedContext(modal.payload);
    }
  };

  useEffect(() => {
    if (modal.state) {
      const { element } = modal.payload;
      setValues({
        sesiones: element.sesiones,
        fecha_inicio: element.fecha_inicio,
        fecha_fin: element.fecha_fin,
        observaciones: element.observaciones,
      });
    }
  }, [modal]);

  return (
    <div>
      <Modal backdrop="static" isOpen={modal.state} toggle={toggle}>
        {modal.payload === null ? null : (
          <ModalHeader>Diente pza. {modal.payload.element.name}</ModalHeader>
        )}
        <ModalBody>
          {modal.payload === null ? null : (
            <FormGroup row className="my-2">
              <Col>
                <div className="d-flex flex-row">
                  <Label className="form-group has-float-label">
                    <span>Sesiones</span>
                    <Input
                      name="sesiones"
                      value={values.sesiones}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          sesiones: e.target.value,
                        });
                        handleChangeSelectedDmg(
                          modal.payload.item,
                          modal.payload.element,
                          e
                        );
                      }}
                      type="number"
                      placeholder="sesiones"
                    />
                  </Label>
                  <Label className="form-group has-float-label">
                    <span>Fecha de inicio</span>
                    <DatePicker
                      onChange={(e) => {
                        setValues({
                          ...values,
                          fecha_inicio: e,
                        });
                        handleChangeSelectedDmg(
                          modal.payload.item,
                          modal.payload.element,
                          e,
                          'fecha_inicio'
                        );
                      }}
                      selected={values.fecha_inicio}
                      placeholderText="aaaa-mm-dd"
                    />
                  </Label>
                  <Label className="form-group has-float-label">
                    <span>Fecha de termino</span>
                    <DatePicker
                      onChange={(e) => {
                        setValues({
                          ...values,
                          fecha_fin: e,
                        });
                        handleChangeSelectedDmg(
                          modal.payload.item,
                          modal.payload.element,
                          e,
                          'fecha_fin'
                        );
                      }}
                      selected={values.fecha_fin}
                      placeholderText="aaaa-mm-dd"
                    />
                  </Label>
                </div>
                <Label className="form-group has-float-label">
                  <span>Observacion</span>
                  <Input
                    onChange={(e) => {
                      setValues({
                        ...values,
                        observaciones: e.target.value,
                      });
                      handleChangeSelectedDmg(
                        modal.payload.item,
                        modal.payload.element,
                        e
                      );
                    }}
                    name="observaciones"
                    value={values.observaciones}
                    type="textarea"
                    placeholder="observación"
                  />
                </Label>
              </Col>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => checkModal()}>
            Guardar
          </Button>{' '}
          <Button color="danger" onClick={() => setCancelModal(modal.payload)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalTrabajo;
