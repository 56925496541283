import { types } from '../../../actions';

export const openModalRadiografia = (data) => ({
  type: types.asistente.paciente.radiografia.openModalRadiografia,
  payload: { data },
});

export const closeModalRadiografia = (data) => ({
  type: types.asistente.paciente.radiografia.closeModalRadiografia,
  payload: { data },
});

export const openModalSubirRadiografia = (data) => ({
  type: types.asistente.paciente.radiografia.openModalSubirRadiografia,
  payload: { data },
});

export const closeModalSubirRadiografia = (data) => ({
  type: types.asistente.paciente.radiografia.closeModalSubirRadiografia,
  payload: { data },
});

export const getAllRadiografiasSuccess = (data) => ({
  type: types.asistente.paciente.radiografia.getAllRadiografiasSuccess,
  payload: { data },
});

export const subirRadiografiaByCita = (data) => ({
  type: types.asistente.paciente.radiografia.subirRadiografiaByCita,
  payload: { data },
});

export const subirRadiografiaByCitaSuccess = (data) => ({
  type: types.asistente.paciente.radiografia.subirRadiografiaByCitaSuccess,
  payload: { data },
});
