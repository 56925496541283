import { types } from '../../actions';

export const loadingRegister = (payload) => ({
  type: types.MesaPartes.Registro.REGISTER_LOADING,
  payload,
});

export const successRegister = (payload) => ({
  type: types.MesaPartes.Registro.REGISTER_SUCCESS,
  payload,
});

export const errorRegister = (payload) => ({
  type: types.MesaPartes.Registro.REGISTER_ERROR,
  payload,
});

export const loadingTipoDocument = (payload) => ({
  type: types.MesaPartes.Registro.TIPO_DOCUMENT_LOADING,
  payload,
});

export const successTipoDocument = (payload) => ({
  type: types.MesaPartes.Registro.TIPO_DOCUMENT_SUCCESS,
  payload,
});
