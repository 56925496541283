import { types } from '../../actions';

const INIT_STATE = {
  registro: {},
  tipoDocumentos: [],
  loading: false,
  error: '',
  autoGenerado: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.MesaPartes.Registro.REGISTER_LOADING:
      return { ...state, loading: true };
    case types.MesaPartes.Registro.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        registro: action.payload,
        autoGenerado: action.payload.autogenerado,
      };
    case types.MesaPartes.Registro.REGISTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.MesaPartes.Registro.TIPO_DOCUMENT_LOADING:
      return { ...state, loading: true };
    case types.MesaPartes.Registro.TIPO_DOCUMENT_SUCCESS:
      return { ...state, loading: false, tipoDocumentos: action.payload };
    default:
      return { ...state };
  }
};
