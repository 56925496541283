import React, { Fragment } from 'react';
import { Image } from 'react-konva';
import { toothHover, toothDisHover } from '../Core/Collision';
import { Constants } from '../Utils/Constants';

const Tooth = ({
  id_paciente,
  token,
  id_cita,
  item,
  odontogram,
  setOdontogram,
  selectedDamage,
  readOnly,
  xPos,
  yPos,
}) => {
  const Imagen = () => {
    let all = Constants();
    let find = item.damages.find(
      (element) => element.damage === all.DIENTE_AUSENTE
    );
    const canvas = document.createElement('canvas');
    canvas.width = 38;
    canvas.height = 85;
    let ctx = canvas.getContext('2d');
    if (typeof find !== 'undefined') {
      ctx.globalAlpha = 0.3;
    }
    ctx.moveTo(0, 0);
    let x = (canvas.width - item.img.width) * 0.5;
    ctx.drawImage(item.img, x, 0);
    return canvas;
  };

  const refactorY = () => {
    let pos = 0;
    if (item.type === 1) {
      pos = item.hover ? item.y - 30 : item.y;
    } else {
      pos = item.hover ? item.y + 13 : item.y;
    }
    return pos;
  };

  return (
    <Fragment>
      {readOnly ? (
        <Image
          id={item.name}
          name={item.id}
          image={Imagen()}
          x={xPos ? xPos : item.x}
          y={yPos ? yPos : item.y}
        />
      ) : (
        <Image
          id={item.name}
          name={item.id}
          scaleY={item.hover ? 1.3 : 1}
          scaleX={item.hover ? 1.2 : 1}
          image={Imagen()}
          x={item.x}
          y={refactorY()}
        />
      )}
    </Fragment>
  );
};

export default Tooth;
