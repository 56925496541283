import { types } from '../../../actions';
import { all, call, fork, takeLatest, put, select } from 'redux-saga/effects';
import { apiCall } from '../../../../config/axiosApiCallWrapper';
import {
  getSearchBoletaFacturaSuccess,
  buscarDocumentoSuccess,
  saveBoletaFacturaSuccess,
  getSearchBoletaFactura,
  sendBoletaFacturaSunatSuccess,
} from './actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function* getSearchBoletaFacturaFunc({ payload }) {
  try {
    const { data, page } = payload;
    const response = yield call(
      apiCall,
      'POST',
      `admin/balanceSunat/envio/getBoletasFacturas?page=${page}`,
      { data }
    );
    if (response.success) {
      yield put(getSearchBoletaFacturaSuccess(response));
    }
  } catch (error) {
    yield console.log(error);
  }
}

function* buscarDocumento({ payload }) {
  try {
    const { setFieldValue, doc, tipoDoc } = payload;
    const response = yield call(
      apiCall,
      'POST',
      'asistente/paciente/buscarDocumento',
      { doc, tipoDoc }
    );
    if (response.success) {
      let nombres = '';
      if (tipoDoc === 'DNI') {
        nombres = `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`;
      } else {
        nombres = response.data.razonSocial;
      }
      yield setFieldValue('nombres', nombres);
    }
    yield put(buscarDocumentoSuccess(response));
  } catch (error) {
    yield console.log(error);
  }
}

function* saveBoletaFactura({ payload }) {
  try {
    const { values } = payload;
    const comprobantes = values;
    const idUser = yield select((state) => state.Auth.data.id);
    const response = yield call(
      apiCall,
      'POST',
      'admin/balanceSunat/envio/editBoletaFactura',
      { comprobantes, idUser }
    );
    if (response.success) {
      yield put(saveBoletaFacturaSuccess(response));
      yield put(getSearchBoletaFactura());
      MySwal.fire({
        title: 'Exito!',
        text: 'Se editó exito!',
        icon: 'success',
      });
    }
  } catch (error) {
    yield console.log(error);
  }
}

function* sendBoletaFacturaSunatFunc({ payload }) {
  try {
    const { values } = payload;
    const comprobantes = {
      ...values,
      nombres: values.paciente,
    };
    const idUser = yield select((state) => state.Auth.data.id);
    const response = yield call(
      apiCall,
      'POST',
      'admin/balanceSunat/sendBoletaSunat',
      { comprobantes, idUser }
    );
    if (response.success) {
      yield put(sendBoletaFacturaSunatSuccess(response));
      yield put(getSearchBoletaFactura());
      MySwal.fire({
        title: 'Exito!',
        text: 'Se envió correctamente!',
        icon: 'success',
      });
    }
  } catch (error) {
    yield console.log(error);
  }
}

export function* watchGetSearchBoletaFactura() {
  yield takeLatest(
    types.admin.balanceSunat.sunat.getSearchBoletaFactura,
    getSearchBoletaFacturaFunc
  );
}

export function* watchBuscarDocumento() {
  yield takeLatest(
    types.admin.balanceSunat.sunat.buscarDocumento,
    buscarDocumento
  );
}

export function* watchSaveBoletaFactura() {
  yield takeLatest(
    types.admin.balanceSunat.sunat.saveBoletaFactura,
    saveBoletaFactura
  );
}

export function* watchSendBoletaFacturaSunat() {
  yield takeLatest(
    types.admin.balanceSunat.sunat.sendBoletaFacturaSunat,
    sendBoletaFacturaSunatFunc
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetSearchBoletaFactura),
    fork(watchBuscarDocumento),
    fork(watchSaveBoletaFactura),
    fork(watchSendBoletaFacturaSunat),
  ]);
}
