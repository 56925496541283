// const url = 'http://localhost/SISTEMA-DENTAL-DEMO-BACKEND-2022/api/';
// export const ServerSocket = 'http://localhost:5000';
// export const UrlFile = 'http://localhost/SISTEMA-DENTAL-DEMO-BACKEND-2022/';
export const facturaToken =
  '?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6Imp0b21va2lqQGdtYWlsLmNvbSJ9.ALhM-MzU82xH21aLQBqgfpKJQJPgtING2CTNAduny80';
export const sunatUrl = 'https://dniruc.apisperu.com/api/v1/ruc/';
export const reniecUrl = 'https://dniruc.apisperu.com/api/v1/dni/';
export const ServerSocket = 'https://socketv5.grupo-sos.com/';
export const UrlFile = 'https://api.demo.grupo-sos.com/';
const url = 'https://api.demo.grupo-sos.com/api/';

export const serviceUrl = {
  baseURL: url,
};

export default url;
