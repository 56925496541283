import { types } from '../../actions';

const initialState = {
  allPatients: [],
  loading: false,
  selectedPatient: {},
  patientPrizes: [],
  modalState: false,
  loadingModalPremios: true,
  recojoPrize: {},
  loadingModalRecojo: true,
  modalRecojoState: false,
  loadingSaveRecojo: false,
  loadingRetiro: false,
  allPatientsRetiro: [],
  modalStateRetiro: false,
  selectedPatientRetiro: {},
  response: null,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AppFairdent.GET_PATIENTS:
      return {
        ...state,
        loading: true,
      };
    case types.AppFairdent.SET_ALL_PATIENTS:
      return {
        ...state,
        allPatients: action.payload,
        loading: false,
      };
    case types.AppFairdent.OPEN_MODAL_PREMIOS:
      return {
        ...state,
        modalState: true,
        selectedPatient: action.payload,
        loadingModalPremios: true,
      };
    case types.AppFairdent.CLOSE_MODAL_PREMIOS:
      return {
        ...state,
        modalState: false,
        selectedPatient: {},
      };
    case types.AppFairdent.SET_PREMIOS_PACIENTE:
      return {
        ...state,
        patientPrizes: action.payload,
        loadingModalPremios: false,
      };
    case types.AppFairdent.OPEN_MODAL_RECOJO:
      return {
        ...state,
        recojoPrize: action.payload,
        loadingModalRecojo: true,
        modalRecojoState: true,
      };
    case types.AppFairdent.CLOSE_MODAL_RECOJO:
      return {
        ...state,
        modalRecojoState: false,
        recojoPrize: {},
      };
    case types.AppFairdent.SAVE_RECOJO_LOADING_TRUE:
      return {
        ...state,
        loadingSaveRecojo: true,
      };
    case types.AppFairdent.SAVE_RECOJO_LOADING_FALSE:
      return {
        ...state,
        loadingSaveRecojo: false,
      };
    case types.AppFairdent.GET_PATIENTS_RETIRO:
      return {
        ...state,
        loadingRetiro: true,
      };
    case types.AppFairdent.SET_ALL_PATIENTS_RETIRO:
      return {
        ...state,
        allPatientsRetiro: action.payload,
        loadingRetiro: false,
      };
    case types.AppFairdent.CLOSE_ALL_SAVE_RETIRO:
      return {
        ...state,
        loadingSaveRecojo: false,
        modalRecojoState: false,
        recojoPrize: {},
        modalState: false,
        selectedPatient: {},
      };
    case types.AppFairdent.OPEN_MODAL_RETIRO:
      return {
        ...state,
        modalStateRetiro: true,
        selectedPatientRetiro: action.payload,
      };
    case types.AppFairdent.CLOSE_MODAL_RETIRO:
      return {
        ...state,
        modalStateRetiro: false,
        selectedPatientRetiro: {},
      };
    case types.AppFairdent.SAVE_RETIRO_LOADING_TRUE:
      return {
        ...state,
        loadingRetiro: true,
      };
    case types.AppFairdent.SAVE_RETIRO_LOADING_FALSE:
      return {
        ...state,
        loadingRetiro: false,
      };
    case types.AppFairdent.CLOSE_SAVE_RETIRO:
      return {
        ...state,
        loadingRetiro: false,
        modalStateRetiro: false,
        selectedPatientRetiro: {},
      };
    case types.AppFairdent.SET_RESPONSE:
      return {
        ...state,
        response: action.payload,
      };
    default:
      return state;
  }
};
