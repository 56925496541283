import { types } from '../../../actions';

export const openModalTrabajo = () => ({
  type:
    types.especialista.paciente.actualTratamiento.STEPPER_OPEN_MODAL_TRABAJO,
});

export const openModalGetPlanTrabajo = (payload) => ({
  type: types.especialista.paciente.actualTratamiento.OPEN_MODAL_TRABAJO,
  payload,
});

export const getPlanTrabajoSuccess = (payload) => ({
  type: types.especialista.paciente.actualTratamiento.GET_PLAN_TRABAJO_SUCCESS,
  payload,
});

export const closeModalGetPlanTrabajo = () => ({
  type: types.especialista.paciente.actualTratamiento.CLOSE_MODAL_TRABAJO,
});

export const checkItemSelectedPlan = (payload) => ({
  type: types.especialista.paciente.actualTratamiento.CHECK_ITEM_SELECTED_PLAN,
  payload,
});

export const checkFinishCitaLoading = (pacienteUser, cita) => ({
  type: types.especialista.paciente.actualTratamiento.CHECK_FINISH_CITA_LOADING,
  payload: { pacienteUser, cita },
});

export const checkFinishCitaSuccess = (response, cita) => ({
  type: types.especialista.paciente.actualTratamiento.CHECK_FINISH_CITA_SUCCESS,
  payload: { response, cita },
});

export const closeModalFinishCita = (payload) => ({
  type: types.especialista.paciente.actualTratamiento.CLOSE_MODAL_FINISH_CITA,
  payload,
});
