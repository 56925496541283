import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { types } from '../../../actions';
import { apiCall } from '../../../../config/axiosApiCallWrapper';
import { getPlanTrabajoSuccess, checkFinishCitaSuccess } from './actions';

function* getPlanTrabajo({ payload }) {
  try {
    const pacienteUser = payload;
    const response = yield call(
      apiCall,
      'POST',
      'especialista/tratamientoactual/getPlanTrabajoById',
      {
        pacienteUser,
      }
    );
    yield put(getPlanTrabajoSuccess(response.planTrabajo));
  } catch (err) {
    console.log(err);
  }
}

function* checkFinishCita({ payload }) {
  try {
    const { pacienteUser, cita } = payload;
    const response = yield call(
      apiCall,
      'POST',
      'especialista/tratamientoactual/checkFinishCita',
      {
        pacienteUser,
      }
    );
    yield put(checkFinishCitaSuccess(response, cita));
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetPlanTrabajo() {
  // eslint-disable-next-line no-use-before-define
  yield takeLatest(
    types.especialista.paciente.actualTratamiento.OPEN_MODAL_TRABAJO,
    getPlanTrabajo
  );
}

export function* watchCheckFinishCita() {
  yield takeLatest(
    types.especialista.paciente.actualTratamiento.CHECK_FINISH_CITA_LOADING,
    checkFinishCita
  );
}

export default function* rootSaga() {
  yield all([fork(watchGetPlanTrabajo), fork(watchCheckFinishCita)]);
}
