import { types } from '../../../actions';
import Actividad from '../../../../views/especialista/paciente/ActualTratamiento/StepperModal/Actividad';
import PlanTrabajo from '../../../../views/especialista/paciente/ActualTratamiento/StepperModal/PlanTrabajo';
import PlanTrabajoFin from '../../../../views/especialista/paciente/ActualTratamiento/StepperModal/PlanTrabajoFin';
import Agendar from '../../../../views/especialista/paciente/ActualTratamiento/StepperModal/Agendar';

const initialState = {
  modalTrabajo: false,
  modalGetPlanTrabajo: false,
  loadingGetPlanTrabajo: false,
  planTrabajoSelected: [],
  modalStepperFinishCita: false,
  loadingStepperFinishCita: false,
  stepsFinishCita: [],
  planTrabajoFin: [],
  tratamientos: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.especialista.paciente.actualTratamiento.OPEN_MODAL_TRABAJO:
      return {
        ...state,
        modalGetPlanTrabajo: true,
        loadingGetPlanTrabajo: true,
      };
    case types.especialista.paciente.actualTratamiento.GET_PLAN_TRABAJO_SUCCESS:
      return {
        ...state,
        planTrabajoSelected: action.payload,
        loadingGetPlanTrabajo: false,
      };
    case types.especialista.paciente.actualTratamiento.CLOSE_MODAL_TRABAJO:
      return {
        ...state,
        modalGetPlanTrabajo: false,
        loadingGetPlanTrabajo: false,
        planTrabajoSelected: [],
      };
    case types.especialista.paciente.actualTratamiento.CHECK_ITEM_SELECTED_PLAN:
      return {
        ...state,
        planTrabajoSelected: state.planTrabajoSelected.map((item) => {
          return item.id === action.payload
            ? {
                ...item,
                active: !item.active,
              }
            : {
                ...item,
                active: false,
              };
        }),
      };
    case types.especialista.paciente.actualTratamiento
      .CHECK_FINISH_CITA_LOADING:
      return {
        ...state,
        modalStepperFinishCita: true,
        loadingStepperFinishCita: true,
      };
    case types.especialista.paciente.actualTratamiento
      .CHECK_FINISH_CITA_SUCCESS: {
      const type = action.payload.cita.tratamiento1;
      const stepMade = [
        type === 'CONSULTA MEDICA'
          ? {
              id: 'planTrabajo',
              name: 'Paso 1',
              desc: 'Plan de trabajo',
              component: PlanTrabajo,
            }
          : {},
        {
          id: 'actividad',
          name: 'Paso 1',
          desc: 'Actividad realizada',
          component: Actividad,
        },
        action.payload.response.tratamientos.length === 0 &&
        action.payload.response.planTrabajo.length > 0
          ? {
              id: 'planTrabajoFin',
              name: 'Paso 1',
              desc: 'Finalizar plan de tratamiento',
              component: PlanTrabajoFin,
            }
          : {},
        action.payload.response.tratamientos.length > 0
          ? {
              id: 'agenda',
              name: 'Paso 1',
              desc: 'Agendar cita',
              component: Agendar,
            }
          : {},
      ];

      let cleanArray = stepMade.filter((el) => Object.keys(el).length !== 0);

      cleanArray = cleanArray.map((item, index) => {
        return {
          ...item,
          name: `Paso ${index + 1}`,
        };
      });

      return {
        ...state,
        stepsFinishCita: cleanArray,
        planTrabajoFin: action.payload.response.planTrabajo,
        tratamientos: action.payload.response.tratamientos,
        loadingStepperFinishCita: false,
      };
    }
    case types.especialista.paciente.actualTratamiento.CLOSE_MODAL_FINISH_CITA:
      return {
        ...state,
        modalStepperFinishCita: false,
      };
    default:
      return state;
  }
};
