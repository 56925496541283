import { types } from '../../../../actions';

export const setSelectedTicket = (data) => ({
  type: types.admin.balanceSunat.categoria.ganancia.SET_SELECTED_TICKET,
  payload: { data },
});

export const sendTickesConvert = (nuevoArreglo, idUser, dia) => ({
  type: types.admin.balanceSunat.categoria.ganancia.sendTicketLoading,
  payload: { nuevoArreglo, idUser, dia },
});

export const sendTickesConvertSuccess = (data) => ({
  type: types.admin.balanceSunat.categoria.ganancia.sendTicketSuccess,
  payload: { data },
});

export const getTicketComprob = (idSucursal, dia) => ({
  type: types.admin.balanceSunat.categoria.ganancia.getComprobantes,
  payload: { idSucursal, dia },
});

export const getTicketComprobSuccess = (response) => ({
  type: types.admin.balanceSunat.categoria.ganancia.getComprobantesSuccess,
  payload: { response },
});

export const sendRegisterSunat = (data) => ({
  type: types.admin.balanceSunat.categoria.ganancia.send_register_sunat,
  payload: { data },
});

export const sendRegisterSunatSuccess = (data) => ({
  type: types.admin.balanceSunat.categoria.ganancia.send_register_sunat_success,
  payload: { data },
});

export const openModalPreviewBoleta = (data) => ({
  type: types.admin.balanceSunat.categoria.ganancia.openModalPreviewBoleta,
  payload: { data },
});

export const closeModalPreviewBoleta = (data) => ({
  type: types.admin.balanceSunat.categoria.ganancia.closeModalPreviewBoleta,
  payload: { data },
});
