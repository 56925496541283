import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
  getReciboCitaSuccess,
  sendRegistroPagoSuccess,
  getCitaIdPagoAdvanceSuccess,
  openModalRecibos,
} from './actions';
import { types } from '../../../actions';
import { apiCall } from '../../../../config/axiosApiCallWrapper';
import NumerosALetras from '../../../../components/NumerosALetras';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function* getReciboCita({ payload }) {
  const { value } = payload;
  const citaDetail = value;
  try {
    const response = yield call(
      apiCall,
      'POST',
      'asistente/cita/getReciboCitaAdvance',
      {
        citaDetail,
      }
    );
    yield put(getReciboCitaSuccess(response));
  } catch (err) {
    console.log(err);
  }
}

function* sendRegistroPago({ payload }) {
  const { value } = payload;
  const formValues = Array.isArray(value)
    ? {
        tipo: 'BOLETAFACTURA',
        montoTotal: value.reduce(
          (acumulator, current) =>
            acumulator + parseInt(current.monto) + parseInt(current.monto2),
          0
        ),
        letras: NumerosALetras(
          value.reduce(
            (acumulator, current) =>
              acumulator + parseInt(current.monto) + parseInt(current.monto2),
            0
          )
        ).toUpperCase(),
        observaciones: '',
        detalles: value,
        tipoAtencion: value[0].tipoAtencion,
        tipoComprob: value[0].tipoComprob,
      }
    : {
        ...value,
        tipo: 'TICKET',
      };
  const { currentCitaSelected } = yield select(
    (state) => state.asistente.paciente.recibos
  );
  const idUser = yield select((state) => state.Auth.data.id);
  try {
    const response = yield call(
      apiCall,
      'POST',
      'asistente/cita/registerPagoPaciente',
      {
        formValues,
        currentCitaSelected,
        idUser,
      }
    );
    if (response.success) {
      yield put(sendRegistroPagoSuccess(response));
      yield put(openModalRecibos(currentCitaSelected));
      MySwal.fire({
        title: 'Exito!',
        text: 'Se ha registrado el recibo correctamente...',
        icon: 'success',
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* getCitaIdPagoAdvance() {
  const { currentCitaSelected } = yield select(
    (state) => state.asistente.paciente.recibos
  );
  try {
    const response = yield call(
      apiCall,
      'POST',
      'asistente/cita/getCitaIdPagoAdvance',
      {
        currentCitaSelected,
      }
    );
    yield put(getCitaIdPagoAdvanceSuccess(response));
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetReciboCita() {
  yield takeEvery(
    types.asistente.paciente.recibos.openModalRecibos,
    getReciboCita
  );
}

export function* watchSendRegistroPago() {
  yield takeEvery(
    types.asistente.paciente.recibos.sendRegistroPago,
    sendRegistroPago
  );
}

export function* watchOpenModalPago() {
  yield takeEvery(
    types.asistente.paciente.recibos.openModalPago,
    getCitaIdPagoAdvance
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetReciboCita),
    fork(watchSendRegistroPago),
    fork(watchOpenModalPago),
  ]);
}
