import { all, fork, takeLatest, call, put, select } from 'redux-saga/effects';
import { apiCall } from '../../../../config/axiosApiCallWrapper';
import {
  getCargosSolicitudSuccess,
  selectFechaSolicitudSuccess,
  sendSolicitudExtraSuccess,
  getPendinSolicitudExtraSuccess,
  sendConfirmationSolicitudSuccess,
  getPendinSolicitudExtra,
} from './actions';
import { types } from '../../../actions';
import { objectToFormData } from '../../../../helpers/Utils';
import { initLoading, stopLoading } from '../../../settings/actions';

function* getCargosSolicitud() {
  try {
    const response = yield call(
      apiCall,
      'POST',
      'rrhh/controlAsistencia/horaExtra/getCargosSolicitud',
      {}
    );
    if (response.success) {
      yield put(getCargosSolicitudSuccess(response));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getFechSolicitud({ payload }) {
  try {
    const { data, values } = payload;
    const response = yield call(
      apiCall,
      'POST',
      'rrhh/controlAsistencia/horaExtra/getHorasExtrasSolicitud',
      { data, values }
    );
    if (response.success) {
      yield put(selectFechaSolicitudSuccess(response));
    }
  } catch (error) {
    console.log(error);
  }
}

function* sendSolicitud({ payload }) {
  yield put(initLoading());
  try {
    const { data } = payload;
    const idUser = yield select((state) => state.Auth.data.id);
    const formData = objectToFormData({ ...data, idUser });
    const response = yield call(
      apiCall,
      'POST',
      'rrhh/controlAsistencia/horaExtra/sendSolicitudHorasExtra',
      formData
    );
    if (response.success) {
      yield put(sendSolicitudExtraSuccess(response));
      yield put(getPendinSolicitudExtra());
      yield put(stopLoading());
    }
  } catch (error) {
    console.log(error);
  }
}

function* getPendinSolicitudExtraFun({ payload }) {
  yield put(initLoading());
  try {
    const { data } = payload;
    let route = 'rrhh/controlAsistencia/horaExtra/getPendinSolicitudExtra';
    if (data === 'accept') {
      route = 'rrhh/controlAsistencia/horaExtra/getAceptSolicitudExtra';
    }
    const idUser = yield select((state) => state.Auth.data.id);
    const response = yield call(apiCall, 'POST', route, { idUser });
    if (response.success) {
      yield put(getPendinSolicitudExtraSuccess(response));
      yield put(stopLoading());
    }
  } catch (error) {
    console.log(error);
  }
}

function* sendConfirmationSolicitud({ payload }) {
  yield put(initLoading());
  try {
    const { data } = payload;
    const idUser = yield select((state) => state.Auth.data.id);
    const { selectedHoraExtraSolicitud } = yield select(
      (state) => state.recursosHumanos.controlAsistencia.horasExtra
    );
    const formData = objectToFormData({
      ...data,
      idUser,
      ...selectedHoraExtraSolicitud,
    });
    const route = 'rrhh/controlAsistencia/horaExtra/sendConfirmationSolicitud';
    const response = yield call(apiCall, 'POST', route, formData);
    if (response.success) {
      yield put(sendConfirmationSolicitudSuccess(response));
      yield put(getPendinSolicitudExtra('accept'));
      yield put(stopLoading());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchOpenModalSolicitud() {
  yield takeLatest(
    types.recursosHumanos.controlAsistencia.horasExtras.openModalSolicitud,
    getCargosSolicitud
  );
}

export function* watchSelectedFechaSolicitud() {
  yield takeLatest(
    types.recursosHumanos.controlAsistencia.horasExtras.selectFechaSolicitud,
    getFechSolicitud
  );
}

export function* watchSendSolicitud() {
  yield takeLatest(
    types.recursosHumanos.controlAsistencia.horasExtras.sendSolicitudExtra,
    sendSolicitud
  );
}

export function* watchGetPendinSolicitudExtra() {
  yield takeLatest(
    types.recursosHumanos.controlAsistencia.horasExtras.getPendinSolicitudExtra,
    getPendinSolicitudExtraFun
  );
}

export function* watchSendConfirmationSolicitud() {
  yield takeLatest(
    types.recursosHumanos.controlAsistencia.horasExtras
      .sendConfirmationSolicitud,
    sendConfirmationSolicitud
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchOpenModalSolicitud),
    fork(watchSelectedFechaSolicitud),
    fork(watchSendSolicitud),
    fork(watchGetPendinSolicitudExtra),
    fork(watchSendConfirmationSolicitud),
  ]);
}
