import { types } from '../../actions';

const initialState = {
  currentEmpleado: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.recursosHumanos.tareas.selectEmpleadoTarea: {
      const data = action.payload;
      return {
        currentEmpleado: data,
      };
    }
    default:
      return state;
  }
};
