import { all, fork, takeLatest, call, put } from 'redux-saga/effects';
import { apiCall } from '../../../config/axiosApiCallWrapper';
import { successRegister, errorRegister, successTipoDocument } from './actions';
import { types } from '../../actions';
import { objectToFormData } from '../../../helpers/Utils';

function* registerMesaPartes({ payload }) {
  try {
    const temp = objectToFormData(payload);
    const response = yield call(
      apiCall,
      'POST',
      'principal/mesaPartes/getRegister',
      temp
    );
    if (response.success) {
      yield put(successRegister(response));
    } else {
      yield put(errorRegister(response.message));
    }
  } catch (error) {
    console.log(error);
    yield put(errorRegister(error));
  }
}

function* getTipoDocumentos() {
  try {
    const response = yield call(
      apiCall,
      'POST',
      'principal/mesaPartes/getTipoDocument',
      {}
    );
    if (response.success) {
      yield put(successTipoDocument(response.data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchTipoDocument() {
  yield takeLatest(
    types.MesaPartes.Registro.TIPO_DOCUMENT_LOADING,
    getTipoDocumentos
  );
}

export function* watchRegister() {
  yield takeLatest(
    types.MesaPartes.Registro.REGISTER_LOADING,
    registerMesaPartes
  );
}

export default function* rootSaga() {
  yield all([fork(watchRegister), fork(watchTipoDocument)]);
}
