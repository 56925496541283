import React, { Fragment, useState, useEffect } from 'react';
import { Layer, Group, Rect, Stage } from 'react-konva';
import CustomPortal from '../../../../../components/odontograma/Models/Portal';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  Alert,
} from 'reactstrap';
import CheckBoxPlanT from '../../../../../components/odontograma/Models/CheckBoxPlanT';
import TextBox from '../../../../../components/odontograma/Models/TextBox';
import Tooth from '../../../../../components/odontograma/Models/Tooth';
import NumberTooth from '../../../../../components/odontograma/Models/NumberTooth';
import ThumbListView from '../../../../../containers/pages/ThumbListViewOdonto';
import Context from '../../../../../components/odontograma/Models/ContextMenu/Context';
import ModalTrabajo from './ModalTrabajo';
import { getDamageGroup } from '../../../../../components/odontograma/Core/Engine';

const PlanTrabajo = ({
  selectedDamages,
  setSelectedDamages,
  odontogram,
  modal,
}) => {
  const [selectedContextMenu, setSelectedContextMenu] = useState(null);
  const [modalTrabajo, setModalTrabajo] = useState({
    state: false,
    payload: null,
  });
  const toogleTrabajo = (payload) => {
    setModalTrabajo({
      state: !modalTrabajo.state,
      payload: payload || null,
    });
  };

  const setSelectedDamage = (e, item, element) => {
    const mousePosition = {
      x: e.evt.x,
      y: e.evt.y,
    };
    setSelectedContextMenu({
      payload: { item, element },
      position: mousePosition,
    });
    setSelectedDamages((damage) => {
      return damage.map((dmg) => {
        if (dmg.damage === item.damage) {
          const dmgTemp = dmg.detalles.map((dtll) => {
            return dtll.id === element.id
              ? {
                  ...dtll,
                  active: true,
                }
              : {
                  ...dtll,
                  active: false,
                };
          });
          return {
            ...dmg,
            detalles: dmgTemp,
          };
        }
        return dmg;
      });
    });
  };

  const setSelectedTrabajo = (payload) => {
    const { item, element } = payload;
    setSelectedDamages((damage) => {
      return damage.map((dmg) => {
        if (dmg.damage === item.damage) {
          const dmgTemp = dmg.detalles.map((dtll) => {
            return dtll.id === element.id
              ? {
                  ...dtll,
                  trabajo: true,
                }
              : dtll;
          });
          const countDmg = dmgTemp.filter((dtll) => dtll.trabajo === true)
            .length;
          return {
            ...dmg,
            detalles: dmgTemp,
            selected: countDmg,
            status: countDmg > 0 ? 'completado' : 'seleccione',
            statusColor: countDmg > 0 ? 'success' : 'danger',
          };
        }
        return dmg;
      });
    });
  };

  const setSelectedContext = (payload) => {
    setSelectedTrabajo(payload);
    toogleTrabajo();
  };

  const handleOnMouseLeave = (payload) => {
    const { item, element } = payload;
    setSelectedContextMenu(null);
    setSelectedDamages((damage) => {
      return damage.map((dmg) => {
        if (dmg.damage === item.damage) {
          const dmgTemp = dmg.detalles.map((dtll) => {
            return dtll.id === element.id
              ? {
                  ...dtll,
                  active: false,
                }
              : {
                  ...dtll,
                  active: false,
                };
          });
          return {
            ...dmg,
            detalles: dmgTemp,
          };
        }
        return dmg;
      });
    });
  };

  const setCancelModal = (payload) => {
    toogleTrabajo();
    handleOnMouseLeave(payload);
  };

  const handleOptionSelected = (option, type) => {
    setSelectedContextMenu(null);
    if (type === 'SELECT') {
      toogleTrabajo(option);
    } else {
      const { item, element } = option;
      setSelectedDamages((damage) => {
        return damage.map((dmg) => {
          if (dmg.damage === item.damage) {
            const dmgTemp = dmg.detalles.map((dtll) => {
              return dtll.id === element.id
                ? {
                    ...dtll,
                    trabajo: false,
                    active: false,
                  }
                : dtll;
            });
            const countDmg = dmgTemp.filter((dtll) => dtll.trabajo === true)
              .length;
            return {
              ...dmg,
              detalles: dmgTemp,
              selected: countDmg,
              status: countDmg > 0 ? 'completado' : 'seleccione',
              statusColor: countDmg > 0 ? 'success' : 'danger',
            };
          }
          return dmg;
        });
      });
    }
  };

  const handleChangeSelectedDmg = (item, element, e, name) => {
    setSelectedDamages((damage) => {
      return damage.map((dmg) => {
        return dmg.damage === item.damage
          ? {
              ...dmg,
              detalles: dmg.detalles.map((dtll) => {
                const value = name ? e : e.target.value;
                const nameVal = name || e.target.name;
                return dtll.id === element.id
                  ? {
                      ...dtll,
                      [nameVal]: value,
                    }
                  : dtll;
              }),
            }
          : dmg;
      });
    });
  };

  const checkItem = (event, productId) => {
    setSelectedDamages((damage) => {
      return damage.map((dmg) => {
        return dmg.id === productId
          ? {
              ...dmg,
              active: !dmg.active,
            }
          : {
              ...dmg,
              active: false,
            };
      });
    });
  };

  const onDisHover = (item, element) => {
    setSelectedDamages((damage) => {
      return damage.map((dmg) => {
        if (dmg.damage === item.damage) {
          const dmgTemp = dmg.detalles.map((dtll) => {
            return dtll.id === element.id
              ? {
                  ...dtll,
                  active: false,
                }
              : {
                  ...dtll,
                  active: false,
                };
          });
          return {
            ...dmg,
            detalles: dmgTemp,
          };
        }
        return dmg;
      });
    });
  };

  const onHover = (item, element) => {
    setSelectedDamages((damage) => {
      return damage.map((dmg) => {
        if (dmg.damage === item.damage) {
          const dmgTemp = dmg.detalles.map((dtll) => {
            return dtll.id === element.id
              ? {
                  ...dtll,
                  active: true,
                }
              : {
                  ...dtll,
                  active: false,
                };
          });
          return {
            ...dmg,
            detalles: dmgTemp,
          };
        }
        return dmg;
      });
    });
  };

  useEffect(() => {
    setSelectedDamages(getDamageGroup(odontogram));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odontogram]);

  return (
    <Container>
      <ModalTrabajo
        handleChangeSelectedDmg={handleChangeSelectedDmg}
        setSelectedContext={setSelectedContext}
        setCancelModal={setCancelModal}
        modal={modalTrabajo}
        toggle={toogleTrabajo}
      />
      {selectedDamages.length === 0 ? (
        <Alert color="warning" className="rounded">
          No has seleccionado ningun diente para tu plan de trabajo
        </Alert>
      ) : (
        <>
          <Row className="mb-3">
            <Col>
              <h5>Seleccione los tratamiento que se trabajará</h5>
            </Col>
          </Row>
          {selectedDamages.map((item, index) => {
            return (
              <Row key={index}>
                <Col>
                  <ThumbListView product={item} onCheckItem={checkItem} />
                  <Collapse isOpen={item.active}>
                    <Card>
                      <CardBody>
                        <h5>Seleccione los dientes a trabajar</h5>
                        <div className="border overflow-auto">
                          <Stage width={item.detalles.length * 82} height={200}>
                            <Layer>
                              {item.detalles.map((element, index2) => {
                                return (
                                  <Fragment key={index2}>
                                    <Group x={index2 * 76 + 2} y={5}>
                                      <Group
                                        id={element.name}
                                        onClick={(e) => {
                                          setSelectedDamage(e, item, element);
                                        }}
                                        onContextMenu={(e) =>
                                          e.evt.preventDefault(true)
                                        }
                                        onMouseLeave={() =>
                                          onDisHover(item, element)
                                        }
                                        onMouseEnter={() =>
                                          onHover(item, element)
                                        }
                                      >
                                        <Rect
                                          x={0}
                                          y={0}
                                          width={75}
                                          height={190}
                                          shadowBlur={10}
                                          strokeWidth={2}
                                          stroke={
                                            element.active ? '#3e874f' : ''
                                          }
                                        />
                                        <Rect
                                          x={0}
                                          y={0}
                                          width={75}
                                          height={190}
                                          shadowBlur={10}
                                          strokeWidth={2}
                                          fill={
                                            element.trabajo ? '#3e874f' : ''
                                          }
                                        />
                                        <TextBox
                                          item={element}
                                          xPos={20}
                                          yPos={10}
                                          readOnly
                                        />
                                        <Tooth
                                          item={element}
                                          xPos={20}
                                          yPos={40}
                                          readOnly
                                        />
                                        <NumberTooth
                                          item={element}
                                          xPos={30}
                                          yPos={175}
                                          readOnly
                                        />
                                        <CheckBoxPlanT
                                          item={element}
                                          modal={modal}
                                          odontogram={odontogram}
                                        />
                                      </Group>
                                    </Group>
                                  </Fragment>
                                );
                              })}
                              {selectedContextMenu && (
                                <CustomPortal>
                                  <Context
                                    {...selectedContextMenu}
                                    handleOnMouseLeave={handleOnMouseLeave}
                                    onOptionSelected={handleOptionSelected}
                                  />
                                </CustomPortal>
                              )}
                            </Layer>
                          </Stage>
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col>
              </Row>
            );
          })}
        </>
      )}
    </Container>
  );
};

export default PlanTrabajo;
