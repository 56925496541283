import {
  CHANGE_LOCALE,
  LOADER_GENERAL_ENABLED,
  LOADER_GENERAL_DISABLED,
} from '../actions';
import { getCurrentLanguage } from '../../helpers/Utils';

const INIT_STATE = {
  locale: getCurrentLanguage(),
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case LOADER_GENERAL_ENABLED: {
      return { ...state, loading: true };
    }
    case LOADER_GENERAL_DISABLED: {
      return { ...state, loading: false };
    }
    default:
      return { ...state };
  }
};
