import React, { useEffect, useState } from 'react';
import { Rect } from 'react-konva';
import { getSurfaces } from '../Core/odonCore';

const CheckBoxPlanT = ({ item, modal, odontogram }) => {
  const [checkTemp, setCheckTemp] = useState(item.checkBoxes);

  useEffect(() => {
    if (modal) {
      // console.log(checkTemp);
      setCheckTemp((check) => {
        return check.map((element) => {
          if (getSurfaces(item.name) === 5) {
            let positionXY = {
              x: 0,
              y: 0,
            };
            switch (element.id) {
              case `${item.name}_M`:
                positionXY = {
                  x: 20 * 1 + 5,
                  y: (15 + 20) * 4.2,
                };
                break;
              case `${item.name}_0`:
                positionXY = {
                  x: 10 * 1 + 5 + 20,
                  y: (15 + 20) * 4.2,
                };
                break;
              case `${item.name}_D`:
                positionXY = {
                  x: 0 * 1 + 5 + 20 * 2,
                  y: (15 + 20) * 4.2,
                };
                break;
              case `${item.name}_V`:
                positionXY = {
                  x: 10 * 1 + 5 + 20,
                  y: 25 * 5.5,
                };
                break;
              case `${item.name}_L`:
                positionXY = {
                  x: 10 * 1 + 5 + 20,
                  y: (15 + 15 * 2) * 3.5,
                };
                break;
              default:
                break;
            }
            return {
              ...element,
              ...positionXY,
            };
          } else {
            let positionXY = {
              x: 0,
              y: 0,
            };
            switch (element.id) {
              case `${item.name}_M`:
                positionXY = {
                  x: 15 * 1 + 10,
                  y: (15 + 20) * 4.2,
                };
                break;
              case `${item.name}_D`:
                positionXY = {
                  x: 5 * 1 + 10 + 20,
                  y: (15 + 20) * 4.2,
                };
                break;
              case `${item.name}_V`:
                positionXY = {
                  x: 10 * 1 + 10 + 10,
                  y: 25 * 5.5,
                };
                break;
              case `${item.name}_L`:
                positionXY = {
                  x: 10 * 1 + 10 + 10,
                  y: (15 + 15 * 2) * 3.5,
                };
                break;
              default:
                break;
            }
            return {
              ...element,
              ...positionXY,
            };
          }
        });
      });
    }
  }, [modal]);

  return checkTemp.map((check, index) => {
    return (
      <Rect
        name={check.id}
        key={index}
        x={check.x}
        y={check.y}
        width={10}
        height={10}
        stroke="black"
        fill={`${check.active ? selectedColor : check.color}`}
        strokeWidth={1}
      />
    );
  });
};

export default CheckBoxPlanT;
