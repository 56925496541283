import { types } from '../../../actions';

export const openModalSolicitud = (data) => ({
  type: types.recursosHumanos.controlAsistencia.horasExtras.openModalSolicitud,
  payload: { data },
});

export const closeModalSolicitud = (data) => ({
  type: types.recursosHumanos.controlAsistencia.horasExtras.closeModalSolicitud,
  payload: { data },
});

export const getCargosSolicitudSuccess = (data) => ({
  type:
    types.recursosHumanos.controlAsistencia.horasExtras
      .getCargosSolicitudSuccess,
  payload: { data },
});

export const selectFechaSolicitud = (data, values) => ({
  type:
    types.recursosHumanos.controlAsistencia.horasExtras.selectFechaSolicitud,
  payload: { data, values },
});

export const selectFechaSolicitudSuccess = (data) => ({
  type:
    types.recursosHumanos.controlAsistencia.horasExtras
      .selectFechaSolicitudSuccess,
  payload: { data },
});

export const clearFechaSolicitud = (data) => ({
  type: types.recursosHumanos.controlAsistencia.horasExtras.clearFechaSolicitud,
  payload: { data },
});

export const sendSolicitudExtra = (data) => ({
  type: types.recursosHumanos.controlAsistencia.horasExtras.sendSolicitudExtra,
  payload: { data },
});

export const sendSolicitudExtraSuccess = (data) => ({
  type:
    types.recursosHumanos.controlAsistencia.horasExtras
      .sendSolicitudExtraSuccess,
  payload: { data },
});

export const getPendinSolicitudExtra = (data = 'solicitud') => ({
  type:
    types.recursosHumanos.controlAsistencia.horasExtras.getPendinSolicitudExtra,
  payload: { data },
});

export const getPendinSolicitudExtraSuccess = (data) => ({
  type:
    types.recursosHumanos.controlAsistencia.horasExtras
      .getPendinSolicitudExtraSuccess,
  payload: { data },
});

export const openModalHoraExtraRevisar = (data) => ({
  type:
    types.recursosHumanos.controlAsistencia.horasExtras
      .openModalHoraExtraRevisar,
  payload: { data },
});

export const closeModalHoraExtraRevisar = (data) => ({
  type:
    types.recursosHumanos.controlAsistencia.horasExtras
      .closeModalHoraExtraRevisar,
  payload: { data },
});

export const sendConfirmationSolicitud = (data) => ({
  type:
    types.recursosHumanos.controlAsistencia.horasExtras
      .sendConfirmationSolicitud,
  payload: { data },
});

export const sendConfirmationSolicitudSuccess = (data) => ({
  type:
    types.recursosHumanos.controlAsistencia.horasExtras
      .sendConfirmationSolicitudSuccess,
  payload: { data },
});

export const selectedOrderOptionFunc = (data) => ({
  type: types.recursosHumanos.controlAsistencia.horasExtras.selectedOrderOption,
  payload: { data },
});
