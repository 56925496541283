import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects';
import { types } from '../../../actions';
import { apiCall } from '../../../../config/axiosApiCallWrapper';
import {
  getTypesRadioSuccess,
  actionSavePresupuestoSuccess,
  getCurrentPresSuccess,
  getCurrentPres,
  requestDeleteRadioSuccess,
  requestEditRadioSuccess,
  radioViewSuccess,
} from './actions';
import { initLoading, stopLoading } from '../../../settings/actions';
import { NotificationManager } from '../../../../components/common/react-notifications';

function* getTiposRadiografia() {
  try {
    const response = yield call(
      apiCall,
      'POST',
      'especialista/paciente/radiografias/getTypesRadio',
      {}
    );
    yield put(getTypesRadioSuccess(response.radiografia));
  } catch (err) {
    console.log(err);
  }
}

function* savePresupuesto() {
  yield put(initLoading());
  try {
    const { selectedtypesRadio } = yield select(
      (state) => state.especialista.paciente.radiografia
    );
    const idUser = yield select((state) => state.Auth.data.id);
    const { id_paciente } = yield select(
      (state) => state.PatientData.id_paciente
    );
    const response = yield call(
      apiCall,
      'POST',
      'especialista/paciente/radiografias/savePresupuesto',
      { selectedtypesRadio, idUser, id_paciente }
    );
    if (response.success) {
      yield put(actionSavePresupuestoSuccess());
      yield put(getCurrentPres());
      yield put(stopLoading());
      NotificationManager.success('Se guardó correctamente', 'Exito!');
    }
  } catch (err) {
    yield put(stopLoading());
    NotificationManager.error('No se pudo guardar correctamente!', 'Error');
    console.log(err);
  }
}

function* getCurrentPresFunc() {
  try {
    const { id_paciente } = yield select(
      (state) => state.PatientData.id_paciente
    );
    const response = yield call(
      apiCall,
      'POST',
      'especialista/paciente/radiografias/getCurrentPres',
      { id_paciente }
    );
    if (response.success) {
      yield put(getCurrentPresSuccess(response.presupuesto));
    }
  } catch (err) {
    console.log(err);
  }
}

function* requestDeleteRadio({ payload }) {
  yield put(initLoading());
  try {
    const sustento = payload.data;
    const { selectedtypesRadio } = yield select(
      (state) => state.especialista.paciente.radiografia
    );
    const idUser = yield select((state) => state.Auth.data.id);
    const { id_paciente } = yield select(
      (state) => state.PatientData.id_paciente
    );
    const response = yield call(
      apiCall,
      'POST',
      'especialista/paciente/radiografias/requestDeleteRadio',
      { id_paciente, idUser, selectedtypesRadio, sustento }
    );
    if (response.success) {
      NotificationManager.success('Se eliminó correctamente', 'Exito!');
      yield put(requestDeleteRadioSuccess());
      yield put(getCurrentPres());
      yield put(stopLoading());
    }
  } catch (err) {
    console.log(err);
    NotificationManager.error('No se pudo eliminar :(!', 'Error');
    yield put(stopLoading());
  }
}

function* requestEditRadio() {
  yield put(initLoading());
  try {
    const { selectedtypesRadio } = yield select(
      (state) => state.especialista.paciente.radiografia
    );
    const idUser = yield select((state) => state.Auth.data.id);
    const { id_paciente } = yield select(
      (state) => state.PatientData.id_paciente
    );
    const response = yield call(
      apiCall,
      'POST',
      'especialista/paciente/radiografias/requestEditRadio',
      { id_paciente, idUser, selectedtypesRadio }
    );
    if (response.success) {
      NotificationManager.success('Se editó correctamente', 'Exito!');
      yield put(requestEditRadioSuccess());
      yield put(getCurrentPres());
      yield put(stopLoading());
    }
  } catch (err) {
    console.log(err);
    NotificationManager.error('No se pudo eliminar!', 'Error :(');
    yield put(stopLoading());
  }
}

function* getRadioView() {
  try {
    const { id_paciente } = yield select(
      (state) => state.PatientData.id_paciente
    );
    const response = yield call(
      apiCall,
      'POST',
      'especialista/paciente/radiografias/getRadioView',
      { id_paciente }
    );
    if (response.success) {
      yield put(radioViewSuccess(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetPlanTrabajo() {
  yield takeLatest(
    types.especialista.paciente.radiografia.openModalPresupuesto,
    getTiposRadiografia
  );
}

export function* watchSavePresupuesto() {
  yield takeLatest(
    types.especialista.paciente.radiografia.savePresupuesto,
    savePresupuesto
  );
}

export function* watchGetCurrentPress() {
  yield takeLatest(
    types.especialista.paciente.radiografia.getCurrentPres,
    getCurrentPresFunc
  );
}

export function* watchRequestDeleteRadio() {
  yield takeLatest(
    types.especialista.paciente.radiografia.requestDeleteRadio,
    requestDeleteRadio
  );
}

export function* watchRequestEditRadio() {
  yield takeLatest(
    types.especialista.paciente.radiografia.requestEditRadio,
    requestEditRadio
  );
}

export function* watchGetRadioView() {
  yield takeLatest(
    types.especialista.paciente.radiografia.openModalRadioView,
    getRadioView
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetPlanTrabajo),
    fork(watchSavePresupuesto),
    fork(watchGetCurrentPress),
    fork(watchRequestDeleteRadio),
    fork(watchRequestEditRadio),
    fork(watchGetRadioView),
  ]);
}
