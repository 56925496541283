import { types } from '../actions';

const initialState = {
  loadingTramites: false,
  tramitesUser: [],
  tramitesUserData: [],
  modalDetallesState: false,
  loadingDetalles: false,
  datosDetalles: [],
  modalArchivosState: false,
  loadingArchivos: false,
  loadingAnexos: false,
  archivosSeguimiento: [],
  anexosSeguimiento: [],
  modalSeguimientoState: false,
  loadingHistorial: false,
  datosHistorial: [],
  fechaInicioHistorial: '',
  modalAccionesState: false,
  dataTempModalAcciones: [],
  datosRegistroAccion: {},
  opcionesDerivar: [],
  loadingAccion: false,
  tipoDocumentos: [],
  loadingDocumentos: false,
  selectedFilter: {
    numero: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MesaPartes.selectedFilter: {
      console.log('data recibido');
      const { data } = action.payload;
      return {
        ...state,
        selectedFilter: data,
      };
    }
    case types.MesaPartes.GET_TRAMITES_USER_LOADING:
      return {
        ...state,
        loadingTramites: true,
      };
    case types.MesaPartes.SET_TRAMITES_USER:
      return {
        ...state,
        loadingTramites: false,
        tramitesUser: action.payload,
        tramitesUserData: action.payload.data,
      };
    case types.MesaPartes.OPEN_MODAL_DETALLES:
      return {
        ...state,
        modalDetallesState: true,
        loadingDetalles: true,
      };
    case types.MesaPartes.CLOSE_MODAL_DETALLES:
      return {
        ...state,
        modalDetallesState: false,
      };
    case types.MesaPartes.SET_DATA_DETALLES:
      return {
        ...state,
        loadingDetalles: false,
        datosDetalles: action.payload,
      };
    case types.MesaPartes.OPEN_MODAL_ARCHIVOS:
      return {
        ...state,
        modalArchivosState: true,
        loadingArchivos: true,
        loadingAnexos: true,
      };
    case types.MesaPartes.CLOSE_MODAL_ARCHIVOS:
      return {
        ...state,
        modalArchivosState: false,
      };
    case types.MesaPartes.SET_ARCHIVOS_SEGUIMIENTO:
      return {
        ...state,
        loadingArchivos: false,
        loadingAnexos: false,
        archivosSeguimiento: action.payload.archivos,
        anexosSeguimiento: action.payload.anexos,
      };
    case types.MesaPartes.OPEN_MODAL_SEGUIMIENTO:
      return {
        ...state,
        modalSeguimientoState: true,
        loadingHistorial: true,
      };
    case types.MesaPartes.CLOSE_MODAL_SEGUIMIENTO:
      return {
        ...state,
        modalSeguimientoState: false,
      };
    case types.MesaPartes.SET_DATA_HISTORIAL:
      return {
        ...state,
        loadingHistorial: false,
        datosHistorial: action.payload.historial,
        fechaInicioHistorial: action.payload.fechaInicio,
      };
    case types.MesaPartes.OPEN_MODAL_ACCIONES:
      return {
        ...state,
        modalAccionesState: true,
        dataTempModalAcciones: action.payload,
      };
    case types.MesaPartes.CLOSE_MODAL_ACCIONES:
      return {
        ...state,
        modalAccionesState: false,
        dataTempModalAcciones: [],
      };
    case types.MesaPartes.LOADING_SAVE_ACCION:
      return {
        ...state,
        datosRegistroAccion: action.payload,
        loadingAccion: true,
      };
    case types.MesaPartes.SET_OPCIONES_DERIVAR:
      return {
        ...state,
        opcionesDerivar: action.payload,
      };
    case types.MesaPartes.SUCCESS_SAVE_ACCION:
      return {
        ...state,
        loadingAccion: false,
        modalAccionesState: false,
        dataTempModalAcciones: [],
      };
    case types.MesaPartes.TIPO_DOCUMENT_LOADING:
      return {
        ...state,
        loadingDocumentos: true,
      };
    case types.MesaPartes.TIPO_DOCUMENT_SUCCESS:
      return {
        ...state,
        loadingDocumentos: false,
        tipoDocumentos: action.payload,
      };
    default:
      return state;
  }
};
